/* 商家后台管理相关模块 */
import Axios from '@/utils/axios.ext';
import api from '@/api';

const state = {};
const getters = {};
const mutations = {};
const actions = {
  getSellerOperateToken(_, password) {
    return Axios({
      method: 'post',
      url: api.getSellerOperateToken(),
      data: { password },
    }).then((resp) => resp.data);
  },
  getOnSellSkuList(_, {
    params,
    signal,
  }) {
    return Axios({
      method: 'post',
      url: api.getOnSellSkuList(),
      data: params,
      signal,
    }).then((resp) => resp.data);
  },
  getOnSellCoinList(_, {
    params,
    signal,
  }) {
    return Axios({
      method: 'post',
      url: api.getOnSellCoinList(),
      data: params,
      signal,
    }).then((resp) => resp.data);
  },
  changeSellPrice(_, {
    params,
  }) {
    return Axios({
      method: 'post',
      url: api.changeSellPrice(),
      data: params,
    }).then((resp) => resp.data);
  },
  changeCoinSellPrice(_, {
    params,
  }) {
    return Axios({
      method: 'post',
      url: api.changeCoinSellPrice(),
      data: params,
    }).then((resp) => resp.data);
  },
  validateSellPriceExcel(_, {
    params,
  }) {
    return Axios({
      method: 'post',
      url: api.validateSellPriceExcel(),
      data: params,
    }).then((resp) => resp.data);
  },
  changeSellPriceExcel(_, {
    params,
  }) {
    return Axios({
      method: 'post',
      url: api.changeSellPriceExcel(),
      data: params,
    }).then((resp) => resp.data);
  },
  changeSellPriceExcelState(_, {
    params,
  }) {
    return Axios({
      method: 'post',
      url: api.changeSellPriceExcelState(),
      data: params,
    }).then((resp) => resp.data);
  },
};

const module = {
  state, getters, mutations, actions,
};

export default module;
