import Vue from 'vue';
import store from '@/store';
import { isMobile } from '@/utils';
import { i18n } from '@/utils/i18n';
import desktopRouter from '@/router/desktop';
import mobileRouter from '@/router/mobile';
import { asyncLoadComponent } from '@/plugins/utils';

let Cashier = null;

const CashierInstance = async (preview) => {
  if (!Cashier) {
    const component = () => import('./cashier.vue');
    try {
      Cashier = await asyncLoadComponent(component);
    } catch (error) {
      return Promise.reject();
    }
  }
  const CashierCtor = Vue.extend(Cashier);
  const instance = new CashierCtor({
    el: document.createElement('div'),
    store,
    i18n,
    router: isMobile() ? mobileRouter : desktopRouter,
  });
  instance.preview = preview;
  instance.assetNum = preview.asset_info.num;
  instance.show = true;
  instance.promise = new Promise((resolve, reject) => {
    instance.$on('close', (payload) => {
      instance.show = false;
      if (payload) {
        resolve(payload);
      }
      reject();
    });
  });

  return instance.promise;
};
CashierInstance.install = (VueInstance) => {
  VueInstance.prototype.$cashier = CashierInstance;
};

export default CashierInstance;
