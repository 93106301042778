import { mapGetters } from 'vuex';

import {
  GOODSTYPE, GOODSTYPE2ROUTERMAP, ProfileGuideEnum, SkuType, TextTypeHreflangMap,
  ROUTERPATH, GOODSTYPEMAPNAME,
} from '@/enums';
import bus from '@/utils/bus';
import LocalStorage from '@/utils/local-storage';
import { isStandalone } from '@/utils';
import SessionStorage from '@/utils/session-storage';

// 设置utm参数
const setUtmParams = () => {
  const UTM_PARAMS = SessionStorage.get('UTM_PARAMS') || {
    utm_source: 'bookmark',
    utm_medium: 'bookmark',
    utm_campaign: '',
  };

  if (UTM_PARAMS) {
    if (!UTM_PARAMS.utm_source) UTM_PARAMS.utm_source = 'bookmark';
  }

  UTM_PARAMS.utm_medium = 'bookmark';
  SessionStorage.set('UTM_PARAMS', UTM_PARAMS);
};

let $loadingToast = null;
const mixin = {
  computed: {
    ...mapGetters(['gameType', 'textType']),
  },
  methods: {
    async $_waitCompleteProfiles(profileGuideArr) {
      for (let i = 0; i < profileGuideArr.length; i += 1) {
        // eslint-disable-next-line
        await this.$_waitCompleteProfile(profileGuideArr[i]);
      }
    },
    async $_waitCompleteProfile(profileGuide) {
      if (this.$_isCompleteProfileNeeded(profileGuide)) {
        await this.$guide(profileGuide);
      }
    },
    $_isCompleteProfileNeeded(profileGuide) {
      const { loginStatus, userInfo } = this.$store.state.Account;
      switch (profileGuide) {
        case ProfileGuideEnum.signin:
          return loginStatus !== 2;
        case ProfileGuideEnum.email:
          return !userInfo.email;
        case ProfileGuideEnum.tradeUrl:
          return !userInfo.trade_url;
        case ProfileGuideEnum.apikey:
          return userInfo.steam_api_key_state !== 2;
        case ProfileGuideEnum.cannotTrade:
          return userInfo.trade_url_state !== 0;
        default:
          throw new Error('Unknown Profile Guide');
      }
    },
    $_handleError(err) {
      console.log(err);
      const { type, data } = err;
      if (type === 'net_error') {
        this.$Message.error(data.message || data.msg);
      } else if (type === 'paramater_error') {
        this.$Message.error(this.$t('global.paramater_error_tips'));
      } else if (type === 'data_error') {
        this.$Message.error(data.error || data.msg);
      } else if (type === 'verify_error') {
        this.$verify().catch(() => {});
      }
    },
    // 全局加载loading
    $_loadingToast(text, delay) {
      if ($loadingToast) {
        $loadingToast.show = false;
        $loadingToast = null;
      }
      if (text) {
        $loadingToast = this.$Message.loading(text, delay);
      }
    },
    $_handlePay(payParams = {}, { goodsId, goodsName, goodsImg } = {}) {
      // 具体购买发起支付流程
      return this.$store.dispatch('postMarketBuy', payParams).then((res) => {
        // 购买成功提醒
        this.$dialog({
          isNew: true,
          title: this.$t(`buy.success_tips.header.${this.gameType}`),
          content: this.$t(`buy.success_tips.content.${this.gameType}`),
          confirmText: this.$t('shareDialog.share_btn'),
          cancelText: this.$t('global.ok'),
          onConfirm: () => {
            const logPrefix = 'page_list_share_';
            this.$logger(`${logPrefix}click`);
            this.$share({
              id: 6,
              img: goodsImg,
              url: `https://${window.location.host}/market/goods/${goodsId}`,
              extraParams: { goods_id: goodsId },
              message: this.$t('shareDialog.buy_success', { goodsName }),
              logPrefix,
            });
          },
        });
        return res;
      }, (err) => {
        // console.log('支付失败');
        this.$_handleError(err);
        return Promise.reject(err);
      }).finally(() => {
        // 更新个人信息
        this.$store.dispatch('fetchUserInfo');
      });
    },
    $_handleAfterFeedback() {
      const name = this.$store.state.game
        ? 'Market.Account.Feedback'
        : 'Account.Feedback';
      // app收银台进入使用replace
      const method = this.$route.name === 'App.Buy'
        ? 'replace' : 'push';
      this.$router[method]({ name });
      bus.$emit('feedback.submit');
    },
    /**
     * 触发安装提示（如果不满足触发条件则不触发）
     * @param {Function} cb 触发安装提示前执行的回调
     *   如果 cb 为函数，将触发安装提示方法作为参数传递给 cb，让 cb 自己决定触发时机
     *   如果 cb 不为函数，则立即触发安装提示
     * @param {Function} failCb 环境无法触发安装的回调
     *   standalone 内的回调
     *   安卓内如果没有捕获到 deferred Prompt 的回调
     */
    $_handleInstall(cb, failCb) {
      const { isInStandalone } = this.$store.state;
      const { deferredInstallPrompt } = this.$store.state;
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

      // 环境无法触发安装
      if (isInStandalone || (!isIOS && !deferredInstallPrompt)) {
        this.$Message.error(this.$t('global.install_app_error'));
        if (failCb && typeof failCb === 'function') failCb();
        return;
      }

      const promptFn = async () => {
        if (isIOS) this.$guide(ProfileGuideEnum.addBookmarkSafari);
        else {
          deferredInstallPrompt.prompt();
          const { outcome } = await deferredInstallPrompt.userChoice;
          if (outcome === 'accepted') {
            this.$store.commit('SET_DEFERRED_INSTALL_PROMPT', null);
            if (isStandalone()) {
              // 设置utm参数
              setUtmParams();
              window.location.reload();
            }
          }
        }
      };

      if (!cb || typeof cb !== 'function') promptFn();
      else cb(promptFn);
    },
    $_localePath(route) {
      const _route = this.$router.resolve(route).route;
      const hreflang = TextTypeHreflangMap[this.textType];
      const { params, ...other } = _route;
      const _params = { ...params, hreflang };
      return { ...other, params: _params };
    },
    // 设置再次购买购物车 渠道收银台页
    $_setBuyAgainCart(billOrder) {
      const {
        num: count,
        game_app: { name: game },
        sku: { goods_type: goodsType, sku_type: skuType },
        cart_asset_info: cartAssetInfo,
        sell_order: { id },
        buy_extra_info: extraInfo,
      } = billOrder;
      const payload = { game };
      if (`${goodsType}` === GOODSTYPE.TOPUP) {
        const infos = extraInfo.map((info) => {
          const {
            name, title, text, field_type: type,
          } = info;
          const newInfo = {
            label: title, name, value: text,
          };
          if (type === 'checkbox') newInfo.display_value = text;
          return newInfo;
        });
        const submit = extraInfo.reduce((prev, { name, value }) => {
          prev[name] = value;
          return prev;
        }, {});
        payload.infos = infos;
        payload.submit = submit;
      }
      // 购物车复购特殊逻辑
      if (cartAssetInfo?.length) {
        payload.orders = cartAssetInfo.map((item) => {
          const { sell_order_id: sellOrderId, num } = item;
          return { id: sellOrderId, count: num, assetNum: 1 };
        });
      } else {
        payload.id = id;
        payload.count = count;
      }
      // 自定义金币复购特殊逻辑
      if (skuType === SkuType.CustomCoin) {
        payload.count = 1;
        payload.assetNum = count;
      }
      LocalStorage.set('cart', payload);
    },
    $_getBuyAgainRouteBySkuInfo(game, skuInfo) {
      if (!game || !skuInfo) return this.$_localePath({ name: 'Index' });
      const route = { params: { game } };
      const { goods_type: goodsType } = skuInfo;
      if (`${goodsType}` === GOODSTYPE.CDKEY
        || `${goodsType}` === GOODSTYPE.ACCOUNT) {
        route.name = 'Detail';
        route.params.goodsType = GOODSTYPE2ROUTERMAP[goodsType];
        route.params.sellorderid = skuInfo.sell_order.id;
      } else if (`${goodsType}` === GOODSTYPE.COINTRADE
        || `${goodsType}` === GOODSTYPE.ITEMTRADE
        || `${goodsType}` === GOODSTYPE.SBC
        || `${goodsType}` === GOODSTYPE.DICE
        || `${goodsType}` === GOODSTYPE.EVENT
        || `${goodsType}` === GOODSTYPE.STARBOX) {
        if (`${goodsType}` === GOODSTYPE.COINTRADE) {
          route.name = 'Coin';
        } else if (`${goodsType}` === GOODSTYPE.SBC) {
          route.name = 'GameSbc';
        } else if (`${goodsType}` === GOODSTYPE.DICE || `${goodsType}` === GOODSTYPE.EVENT || `${goodsType}` === GOODSTYPE.STARBOX) {
          route.name = 'GameDice';
          route.params.goodsType = ROUTERPATH[GOODSTYPEMAPNAME[goodsType]];
        } else {
          route.name = 'Gameitem';
        }
        const query = skuInfo.attribute.reduce((prev, { key, value }) => {
          prev[key] = value;
          return prev;
        }, {});
        route.query = query;
      } else {
        route.name = 'Topup';
        const query = {};
        query.assetid = skuInfo.id;
        route.query = query;
      }
      return this.$_localePath(route);
    },
    // 获取再次购买路由 去到商品详情页
    $_getBuyAgainRoute(billOrder) {
      const {
        game_app: { name: game },
        mode, sku, sell_order: sellOrder,
      } = billOrder;
      const {
        goods_type: goodsType,
        attribute = [],
      } = sku;
      const route = {};
      if (mode === 2) {
        route.params = { game };
        if (`${goodsType}` === GOODSTYPE.CDKEY
          || `${goodsType}` === GOODSTYPE.ACCOUNT) {
          route.name = 'Detail';
          route.params.goodsType = GOODSTYPE2ROUTERMAP[goodsType];
          route.params.sellorderid = sellOrder.id;
        } else if (`${goodsType}` === GOODSTYPE.COINTRADE
          || `${goodsType}` === GOODSTYPE.ITEMTRADE
          || `${goodsType}` === GOODSTYPE.SBC
          || `${goodsType}` === GOODSTYPE.DICE
          || `${goodsType}` === GOODSTYPE.EVENT
          || `${goodsType}` === GOODSTYPE.STARBOX) {
          if (`${goodsType}` === GOODSTYPE.COINTRADE) {
            route.name = 'Coin';
          } else if (`${goodsType}` === GOODSTYPE.SBC) {
            route.name = 'GameSbc';
          } else if (`${goodsType}` === GOODSTYPE.DICE || `${goodsType}` === GOODSTYPE.EVENT || `${goodsType}` === GOODSTYPE.STARBOX) {
            route.name = 'GameDice';
            route.params.goodsType = ROUTERPATH[GOODSTYPEMAPNAME[goodsType]];
          } else {
            route.name = 'Gameitem';
          }
          const query = attribute.reduce((prev, { key, value }) => {
            prev[key] = value;
            return prev;
          }, {});
          route.query = query;
        } else {
          route.name = 'Topup';
          const query = {};
          if (`${goodsType}` === GOODSTYPE.TOPUPLOGIN) query.tab = 'login-top-up';
          query.assetid = sku.id;
          if (sku.info?.subgame_desc) {
            if (attribute) {
              // 新的query数据从attribute中获取
              const list = attribute || [];
              list.forEach((item) => {
                if (item.show_options.includes('tab')) {
                  query[item.key] = item.value;
                }
              });
            } else {
              // 兼容旧数据
              query.region = sku.info.subgame_desc;
            }
          }
          route.query = query;
        }
      } else {
        route.name = 'Market.Goods';
        route.params = { goods_id: sku.spu_id };
      }
      return this.$_localePath(route);
    },
    // 跳转再次购买页面
    // 内部处理部分特殊情况
    // 决定是跳转收银台页 还是 商品详情页
    $_goBuyAgain(billOrder, type = 'push') {
      // 没有 utm 的情况下 设置 utm_source 为 buy_again
      // 用于部分游戏特殊 utm 才开放显示的需求
      const UTMPARAMS = SessionStorage.get('UTM_PARAMS') || {};
      const HASURM = Object.keys(UTMPARAMS).length > 0;
      if (!HASURM) {
        SessionStorage.set('UTM_PARAMS', { utm_source: 'buy_again' });
      }
      const route = this.$_getBuyAgainRoute(billOrder);
      const {
        mode,
        sku: { goods_type: goodsType },
        game_app: { name: game },
      } = billOrder;
      if (mode === 2) {
        if (`${goodsType}` !== GOODSTYPE.TOPUP || game !== 'knivesout') {
          this.$_setBuyAgainCart(billOrder);
          this.$router[type](this.$_localePath({ name: 'Payment' }));
          return;
        }
      }
      this.$router[type](this.$_localePath(route));
    },
  },
};

const Mixins = {};

Mixins.install = (VueInstance) => {
  VueInstance.mixin(mixin);
};

export default Mixins;
