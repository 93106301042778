<template>
  <div :key="seed" class="c-nav__games" @mouseleave="$logger('c2c_tabbar_view')">
    <div class="c-nav__games-inner">
      <span class="font16r-min">{{ $t("lootBar.nav_games") }}</span>
      <w-icon class="icon" :size="16" name="icon_arrow_down" />
    </div>
    <div class="c-nav__games-popover">
      <div v-if="hotAppList && hotAppList.length" class="c-nav__games-wrapper games-hot">
        <div class="c-nav__games-title font15r-min">{{ $t("lootBar.nav_popular_games") }}</div>
        <div class="c-nav__games-container">
          <ul>
            <li v-for="(item, index) of hotAppList" :key="index">
              <router-link :to="handleResolveGameUrl(item)"
                @click.native="handleCloseRoute(item, 'c2c_popular_game_click')">
                <img :src="item?.game_extra_info?.list_icon_url
                  +'?fop=imageView/2/w/48/h/48'" />
                <span class="font15r-min">{{ item.desc }}</span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="allAppList && allAppList.length" class="c-nav__games-wrapper games-all">
        <div class="c-nav__games-title font15r-min">{{ $t("lootBar.nav_all_games") }}</div>
        <div class="c-nav__games-container">
          <ul>
            <li v-for="(item, index) of allAppList" :key="index">
              <router-link class="font15r-min" :to="handleResolveGameUrl(item)"
                @click.native="handleCloseRoute(item, 'c2c_allgame_click')">
                {{ item.desc }}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from './Games.mixin';

export default {
  mixins: [mixin],
  data() {
    return {
      seed: 0,
    };
  },
  methods: {
    handleCloseRoute(item, logger) {
      this.seed += 1;

      this.$logger(logger, { game: item.desc });
    },
  },
};
</script>

<style lang="less">
.c-nav__games {
  position: relative;

  &:hover {
    color: var(--el-wf1);

    .c-nav__games-inner {
      svg {
        transform: rotate(180deg);
      }
    }

    .c-nav__games-popover {
      display: flex;
    }
  }

  .c-nav__games-inner {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    svg {
      margin-left: 4px;
      transition: all 0.15s ease;
    }
  }
}

.c-nav__games-popover {
  position: fixed;
  top: 80px;
  left: 0;
  right: 0;
  display: none;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0 auto;
  background: var(--nc-g3);
  border-radius: 0 0 var(--radius-4) var(--radius-4);
  box-shadow: var(--shadow-3);
  .c2cPcMedia();
  width: 1000px;
  z-index: 100000;

  &::after {
    content: "";
    position: absolute;
    bottom: -48px;
    left: 0;
    right: 0;
    height: 48px;
    background: transparent;
    cursor: default;
  }

  .c-nav__games-wrapper {
    flex: 3 0 0;
    border-radius: 0 0 var(--radius-4) var(--radius-4);

    .c-nav__games-title {
      display: flex;
      align-items: flex-start;
      padding: 14px 16px;
      color: var(--el-wf1);
      border-bottom: 1px solid var(--al-wal1);

      &::before {
        content: "";
        margin-right: 4px;
        width: 20px;
        height: 20px;
        background: no-repeat center;
        background-size: 100%;
      }
    }

    .c-nav__games-container {
      ul {
        li {
          a {
            display: block;
            transition: all 0.15s ease;

            &:hover {
              background: var(--nc-g4);
              opacity: 1;
            }

            &:active {
              background: var(--nc-g4);
              opacity: 0.5;
            }
          }
        }
      }
    }

    .c-nav__games-container {
      max-height: 288px;
      overflow-y: auto;
    }

    &.games-hot {
      .c-nav__games-title {
        &::before {
          background-image: url("~@/assets/images/icon_emoji_fire.png");
        }
      }

      .c-nav__games-container {
        ul {
          display: flex;
          flex-wrap: wrap;

          li {
            width: 50%;
            box-sizing: border-box;

            &:nth-child(2n) {
              padding-left: 16px;
            }

            a {
              display: flex;
              align-items: center;
              padding: 12px 32px;

              img {
                margin-right: 8px;
                width: 24px;
                height: 24px;
                border-radius: var(--radius-4);
              }

              span {
                max-width: 269px;
                color: var(--el-wf1);
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                box-sizing: border-box;
              }
            }
          }
        }
      }
    }

    &.games-all {
      flex: 1 0 0;
      background: #575757;

      .c-nav__games-title {
        &::before {
          background-image: url("~@/assets/images/icon_emoji_game.png");
        }
      }

      .c-nav__games-container {
        ul {
          li {
            a {
              padding: 15px 32px;
              max-width: 365px;
              color: var(--el-wf1);
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              box-sizing: border-box;
            }
          }
        }
      }
    }
  }

  @media (min-width: 1989px) {
    left: 394px;
    margin: 0;
  }

  @media (max-width: 1079px) {
    width: auto;
    margin: 0 40px;
  }

  @media (max-width: 750px) {
    flex-direction: column;

    .c-nav__games-wrapper {
      flex: 1 0 0;
      width: 100%;
    }
  }
}
</style>
