<template>
  <div class="dialog-coupon-list" :style="{backgroundImage:bg&&`url(${bg})`}">
    <div class="dialog-coupon-list-left">
      <div v-if="coupon.coupon_type === 1" class="discount-coupon"
        :style="color?`color:${color}`:''">
        <div>{{ couponText }}<span class="font12b">%</span></div>
        <div class="font15b">OFF</div>
      </div>
      <div v-else-if="coupon.coupon_type === 2" class="discount-coupon minus"
        :style="color?`color:${color}`:''">
        <div>
          <span class="font14b">-{{ currencySymbol }}</span>
          <span :class="{long: offLength > 5}">{{ couponText | formatPrice }}</span>
        </div>
      </div>
    </div>
    <div class="dialog-coupon-list-right">
      <div class="dialog-coupon-info">
        <div class="dialog-coupon-info-name font20b-min"
          :style="color?`color:${color}`:''">{{ coupon?.name }}</div>
        <!-- 2025.1.15 节省是0元的时候不需要显示出来 -->
        <div v-if="coupon.coupon_type === 1 && maxDiscount" class="dialog-coupon-info-money">
          {{ $t('evaluate.evaluate_max_amount', {maxDiscount}) }}</div>
        <div v-else-if="coupon.coupon_type !== 1" class="dialog-coupon-info-money">{{ coupon?.name }}</div>
        <div class="dialog-coupon-info-desc font14r-min"
          :style="color?`color:${color}`:''"
          v-html="coupon?.rule_desc"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { COUPON_TYPE } from '@/enums/coupon';

export default {
  props: {
    coupon: {
      type: Object,
      default: () => ({}),
    },
    bg: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
    };
  },
  computed: {
    currencySymbol() {
      return this.$store.getters.currencySymbol;
    },
    // 折扣最大的金额, 单位分，需要转成元
    maxDiscount() {
      const amount = this.coupon?.coupon_setting?.max || 0;
      if (amount <= 0) {
        return 0;
      }
      const { formatPrice } = this.$options.filters;
      return this.currencySymbol + formatPrice(new window.Big(amount).div(100).toString());
    },
    offLength() {
      const amount = this.coupon?.coupon_setting?.off || 0;
      const { formatPrice } = this.$options.filters;
      const money = this.currencySymbol + formatPrice(new window.Big(amount).div(100).toString());
      return money.length;
    },
    couponText() {
      if (this.coupon?.coupon_type === COUPON_TYPE.sale) {
        const discount = this.coupon?.coupon_setting?.discount || 0;
        return new window.Big(discount).times(100).toNumber();
      }
      if (this.coupon?.coupon_type === COUPON_TYPE.discount) {
        const off = this.coupon?.coupon_setting?.off || 0;
        return new window.Big(off).div(100).toNumber();
      }
      return 0;
    },
  },
  methods: {
  },
};
</script>

  <style lang="less" scoped>
  .dialog-coupon-list {
    display: flex;
    width: 100%;
    height: 124px;
    background: center/cover url('~@/assets/images/new_coupon_bg.png') no-repeat;
    margin-bottom: 10px;
    padding: 0 6px;
    &:last-child {
      margin-bottom: 0;
    }
    @media (max-width: 750px) {
      width: 296px;
      height: 96px;
      margin: 0 auto 10px;
    }
    .dialog-coupon-list-left {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 112px;
      height: 124px;
      flex-shrink: 0;
      @media (max-width: 750px) {
        width: 85px;
        height: 96px;
      }
      .discount-coupon {
        text-align: center;
        font-size: 52px;
        line-height: 1;
        font-weight: 600;
        color: var(--fu-red1);
        &.minus {
          font-size: 40px;
        }
        .long {
          font-size: 18px;
          @media (max-width: 750px) {
            font-size: 15px;
          }
        }
        @media (max-width: 750px) {
          font-size: 40px;
          &.minus {
            font-size: 32px;
          }
        }
      }
    }
    .dialog-coupon-list-right {
      display: flex;
      // flex-direction: column;
      // justify-content: center;
      align-items: center;
      flex: 1;
      height: 124px;
      // margin-left: 4px;
      @media (max-width: 750px) {
        height: 96px;
        .dialog-coupon-info-name {
          font-size: 18px;
          line-height: 22px;
        }
        .dialog-coupon-info-money {
          max-width: 180px;
          font-size: 14px;
        }
      }
      .dialog-coupon-info {
        margin-left: 15px;
        .dialog-coupon-info-name {
          color: var(--fu-red1);
          margin-bottom: 4px;
          display: -webkit-box;
          -webkit-line-clamp: 2; /* 显示两行文本 */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .dialog-coupon-info-money {
          display: inline-flex;
          max-width: 245px;
          background: var(--fu-red1);
          color: var(--el-wf1);
          padding: 2px 8px;
          border-radius: 2px;
          font-size: 15px;
          line-height: 18px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .dialog-coupon-info-desc {
          margin-top: 4px;
          color: var(--el-gf1);
          max-width: 240px;
          &, &:deep(p, div) {
            display: -webkit-box;
            -webkit-line-clamp: 2; /* 显示两行文本 */
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          @media (max-width: 750px) {
            display: none;
          }
        }
      }
    }
  }
  </style>
