import { mapGetters } from 'vuex';
import { LoginStatus, ProfileGuideEnum, SERVICETYPEROUTENAME } from '@/enums';
import { guid, isGoogleBot } from '@/utils/index';
import ua from '@/utils/ua';
import { disableAutoSelect } from '@/utils/googleIdentityService';
import GlobalNotice from '@/components/GlobalNotice.vue';
import ModalRegCopuon from '@/views/components/ModalRegCopuon/ModalRegCopuon.vue';
import DialogCoupon from '@/views/components/DialogCoupon/DialogCoupon.vue';
import WidgetIm from '@/views/components/WidgetIm.vue';

import mixin from './App.common.mixin';
import LocalStorage from './utils/local-storage';
import SessionStorage from './utils/session-storage';
import { TASK, startupRunner } from './utils/startup';
import bus from './utils/bus';
import debounce from './utils/debounce';

export default {
  components: {
    GlobalNotice,
    ModalRegCopuon,
    DialogCoupon,
    WidgetIm,
  },
  mixins: [mixin],
  data() {
    return {
      showAnnouncement: false,
      showInstallCoupon: false,
      showInstallCouponResolve: null,

      reloading: false,
      isloginNoticeId: null,
      hasModalRegCopuon: false, // 是否显示注册优惠券弹窗
      appStatus: {}, // 当前应用状态

      updateExists: false,
      dialogCouponRepurchase: null,
      dialogCouponGame: null,
      pwaReward: null,
    };
  },
  computed: {
    ...mapGetters(['announcement', 'announcementGame', 'loginStatus', 'preventLoginNotice', 'userInfo', 'gameType', 'userSubgame', 'subgames', 'currencySymbol', 'currencyCode', 'currencyRate', 'textType', 'notifications', 'sightseerToken']),
    isInBuffApp() {
      return ua.os.client;
    },
    isInStandalone() {
      return this.$store.state.isInStandalone;
    },
    loginNotice() {
      return this.notifications.login_notice[0];
    },
    isShowLoginNotice() {
      return this.notifications?.login_notice?.length
               && this.isloginNoticeId !== this.loginNotice.id;
    },
    isLogined() {
      return this.loginStatus === LoginStatus.logined;
    },
    showRepurchaseDialogCoupon() {
      return this.dialogCouponRepurchase?.show
        && this.$route.name.indexOf('Account.Buy') !== -1;
    },
    installCouponInfo() {
      const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
      const result = {
        title: this.$t('bookmark.receive_install_tips'),
        desc: isIOS ? this.$t('bookmark.receive_install_desc_ios') : this.$t('bookmark.receive_install_desc'),
        btnText: this.$t('bookmark.receive_install_go_login'),
      };
      let routeName = 'Account.Coupon';

      if (this.pwaReward) {
        if (this.pwaReward.point) {
          result.title = this.$t('bookmark.receive_install_point_tips', { point: this.pwaReward.point });
          result.desc = this.$t('bookmark.check_install_point');
          result.btnText = this.$t('lootBarOrderOptimize.point_detail');
          routeName = 'Account.MyPoint';
        } else if (this.pwaReward.coupons && this.pwaReward.coupons.length) {
          const item = this.pwaReward.coupons[0];
          const discount = item?.coupon_setting?.discount || 0;
          result.title = this.$t('bookmark.receive_install_reward_coupon_tips', {
            coupon: new window.Big(discount).times(100).toNumber() || 5,
          });
          result.desc = this.$t('bookmark.check_install_coupon');
          result.btnText = this.$t('bookmark.check_coupon_go_list');
          routeName = 'Account.Coupon';
        }
      }

      result.routeName = routeName;

      return result;
    },
  },
  watch: {
    isLogined(val) {
      if (val) {
        const installCouponClaimed = LocalStorage.get('install-coupon-claimed');
        if (this.isInStandalone && !installCouponClaimed) {
          this.handleClaimInstallCoupon(true);
        }
        this.regShareCoupon();
        LocalStorage.remove('sightseer-token');
        window.clearInterval(this.timerSightseer);
      } else {
        if (this.$route.meta.loginRequired) {
          this.$router.replace(this.$_localePath({ name: 'Index' }));
        }
        this.hasModalRegCopuon = true;
        this.setGuid();
        this.getSightseerMsg();
        this.timerSightseer = window.setInterval(() => {
          this.getSightseerMsg();
        }, 10 * 1e3);
        disableAutoSelect();
        LocalStorage.remove('TOPUPFROM');
      }
    },
    userInfo(val, oldVal) {
      if (val && !oldVal) {
        if (val.user_type !== 2) {
          this.postImVistorMerge();
        }
      }
    },
    showRepurchaseDialogCoupon(val) {
      if (val) {
        // 展示弹窗之后将通知标记为已读
        this.$store.dispatch('getMessageNotificationRead', {
          noticeType: 3,
        }).catch(() => {});
      }
    },
  },
  methods: {
    updateAvailable() {
      this.$store.dispatch('getVersion').then((version) => {
        if (version !== this.$store.state.appVersion) {
          this.$logger('pwa_update_view');
          this.updateExists = true;
          this.$store.commit('UPDATE_APP_VERSION', { version });
        }
      });
    },
    cancelUpdate() {
      this.$logger('pwa_update_close_click');
      this.updateExists = false;
    },
    refreshApp() {
      this.$logger('pwa_update_confirm_click');
      setTimeout(() => { window.location.reload(); }, 50);
    },
    setGuid() {
      if (!LocalStorage.get('sightseer-token')) {
        LocalStorage.set('sightseer-token', guid());
        this.$store.commit('SET_SIGHTSEER_TOKEN', LocalStorage.get('sightseer-token'));
      }
    },
    /**
     * 设置app当前处理的状态
     * 1.新客活动中如果关闭了弹窗，需要在导航处显示指引，这里加一个全局状态处理： inRegCoupon
     */
    setAppStatus({ status, value } = {}) {
      this.$set(this.appStatus, status, !!value);
    },
    async postImVistorMerge() {
      try {
        await this.$store.dispatch('postImVistorMerge');
      } catch (err) {
        this.$_handleError(err);
      }
    },
    handleContentClick() {
      this.$logger('home_notice_click');
      const hostOfAnnouncementLink = new URL(this.announcement.link).host;
      const hostOfCurrentPage = window.location.host;
      if (hostOfAnnouncementLink === hostOfCurrentPage) {
        window.location.href = this.announcement.link;
      } else {
        window.open(this.announcement.link);
      }
    },
    handleCloseAnnouncement() {
      this.$store.dispatch('clearMessageAnnouncement');
    },
    handleClaimInstallCouponClose() {
      this.showInstallCoupon = false;
      if (this.showInstallCouponResolve) {
        this.showInstallCouponResolve();
        this.showInstallCouponResolve = null;
      }
    },
    handleClaimInstallCoupon(showClaimedToast) {
      this.showInstallCoupon = false;
      if (!this.isLogined) {
        this.showInstallCoupon = true;
        this.$logger('c2c_pwa_coupon_view', { type: 'new_user' });
        return;
      }
      this.$store.dispatch('postGetPwaCoupon').then((res) => {
        // 获取优惠信息
        if (res?.reward) this.pwaReward = res.reward;

        this.showInstallCoupon = true;

        const loggerType = this.pwaReward && this.pwaReward.point ? { type: 'point' } : { type: 'coupon' };
        this.$logger('c2c_pwa_coupon_view', loggerType);

        LocalStorage.set('install-coupon-claimed', true);
      }).catch((err) => {
        const { status } = err.data;
        if (status === 'Coupon Has Been Got') {
          LocalStorage.set('install-coupon-claimed', true);
          if (!showClaimedToast) return;
        }
        this.$_handleError(err);
      });
    },
    async handleInstallCouponConfirm(routeName = 'Account.Coupon') {
      let loggerType = !this.pwaReward ? { type: 'new_user' } : { type: 'coupon' };
      if (this?.pwaReward?.point) loggerType = { type: 'point' };
      this.$logger('c2c_pwa_coupon_click', loggerType);

      if (!this.isLogined) {
        this.$guide(ProfileGuideEnum.signin).catch(() => {});
      } else {
        this.showInstallCoupon = false;
        // 跳转前更新用户信息
        this.$store.dispatch('fetchAssetBrief');
        this.$router.push({ name: routeName });
      }
    },
    async updateNeedFillInfoOrderAmount() {
      let resp;
      try {
        resp = await this.$store.dispatch('getMarketBuyHistory', {
          pageNum: 1,
          pageSize: 1,
          game: 'main',
        });
      } catch (err) {
        this.$_handleError(err);
      }
      this.$store.commit('SET_SUBMIT_INFO_COUNT', resp?.submit_info_count);
    },
    handleLoginNoticeToastCoupon(loginNoticeInfo) {
      const { get_coupon_success: couponSuccess } = loginNoticeInfo.extra;
      const { title } = loginNoticeInfo;
      // 获取优惠券成功
      if (couponSuccess) {
        this.$Message.success(title);
      // 获取优惠券失败
      } else {
        this.$Message.error(title);
      }
      // 已读提醒
      this.$store.dispatch('getMessageNotificationRead', { noticeType: 3 }).catch(() => {});
    },
    handleLoginNoticeDialogCoupon(loginNoticeInfo) {
      const { extra: { coupon_info: coupon }, title } = loginNoticeInfo;
      this.dialogCouponRepurchase = {
        show: true,
        coupons: [coupon],
        desc: title,
        extra: {
          left_ribbon_url: 'https://c2c.fp.guinfra.com/file/671f5856f0f02a55617e8b0eCEMQCfck03',
          right_ribbon_url: 'https://c2c.fp.guinfra.com/file/671f585d7f595d938a553105pOiymQJk03',
          header_bg_url: 'https://c2c.fp.guinfra.com/file/671f586be68732aaa97af081M1AYsNMi03',
          header_bg_url_m: 'https://c2c.fp.guinfra.com/file/671f586473e52f1a20ade6a5ELz9Ze0s03',
          header_bg_color: '#ff6200',
          btn_bg_color: '#191522',
          btn_text_color: '#ff9401',
        },
      };
    },
    handleLoginNoticeExistUserCoupon(loginNoticeInfo) {
      const {
        extra: {
          coupons,
          coupon_background_img: couponBg,
          display_setting: displaySetting,
          bonus_text: bonusText,
        },
      } = loginNoticeInfo;
      this.dialogCouponGame = {
        type: 'exist-user-coupon',
        show: true,
        coupons,
        couponBg,
        desc: this.$t('exist_user_coupon.tips'),
        bonusText,
        extra: displaySetting || {},
      };
      bus.$emit('coupon.granted', coupons);
      this.$logger('page_c2c_return_user_coupon_view');
      this.$store.dispatch('getMessageNotificationRead', { noticeType: 3 }).catch(() => {});
    },
    handleConfirmDialogCouponGame() {
      if (this.dialogCouponGame.type === 'exist-user-coupon') {
        this.$logger('page_c2c_return_user_coupon_claim_click');
      }
      this.dialogCouponGame.show = false;
    },
    handleLoginNoticeBuyNotice(loginNoticeInfo) {
      const { scene, btn_text: btnText } = loginNoticeInfo.extra;
      const confirmText = {
        failed_buyer_submit_order_extra: btnText || this.$t('coin.get_coin'),
        buyer_cooperate_face_deliver: btnText || this.$t('nav.contact_support'),
        delivering_buyer_submit_order_extra: btnText || this.$t('nav.contact_support'),
        buyer_transfer_not_enabled: btnText || this.$t('nav.contact_support'),
        buyer_submit_coin_order_extra: btnText || this.$t('coin.get_coin'),
        buyer_submit_item_order_extra: btnText || this.$t('gameItemOrder.submit_account'),
        cfb25_player_info_error: btnText || this.$t('nav.contact_support'),
        cfb25_not_player_info: btnText || this.$t('coin.get_coin'),
      }[scene] || this.$t('lootBar.contact_seller');

      // 在提取金币或者支付结果页时
      // 不判断提醒弹窗场景，屏蔽全部弹窗
      if (this.preventLoginNotice) {
        this.$store.dispatch('getMessageNotificationRead', { noticeType: 3 }).then(() => {});
        return;
      }
      if (this.dialogLoginNoticeInstance) {
        this.dialogLoginNoticeInstance.handleClose();
      }
      this.dialogLoginNoticeInstance = this.$dialog({
        isNew: true,
        title: loginNoticeInfo.title,
        content: loginNoticeInfo.content,
        closeBtn: null,
        cancelBtn: null,
        confirmText,
        onConfirm: () => {
          this.$logger('home_c2c_buyernotice_click', {
            textmode: loginNoticeInfo.id,
          });
          const query = scene === 'buyer_submit_coin_order_extra' || scene === 'buyer_submit_item_order_extra' || scene === 'cfb25_not_player_info'
            ? { coin: 'extractCoin', game: loginNoticeInfo.extra.game_name }
            : { chat: 'imChat', sellId: loginNoticeInfo.extra.seller_id };
          let route = {
            name: 'Account.Buy.Order',
            params: { orderId: loginNoticeInfo.extra.bill_order_id },
            query,
          };
          if (scene === 'failed_buyer_submit_order_extra') {
            route = {
              name: SERVICETYPEROUTENAME[loginNoticeInfo.extra.service_type],
              params: { game: loginNoticeInfo.extra.game_name },
            };
          }
          this.$store.dispatch('getMessageNotificationRead', { noticeType: 3 }).then(() => {
            this.$router.push(route);
          }).catch((err) => {
            this.isloginNoticeId = null;
            this.$_handleError(err);
          });
        },
      }).instance;
    },
    handleLoginNotice() {
      const loginNoticeInfo = this.loginNotice;
      // 邀请获取优惠券的提示
      const { scene } = loginNoticeInfo.extra;
      const handler = {
        invite_success: this.handleLoginNoticeToastCoupon,
        reg_success: this.handleLoginNoticeToastCoupon,
        buyer_submit_order_extra: this.handleLoginNoticeBuyNotice,
        failed_buyer_submit_order_extra: this.handleLoginNoticeBuyNotice,
        buyer_cooperate_face_deliver: this.handleLoginNoticeBuyNotice,
        buyer_submit_coin_order_extra: this.handleLoginNoticeBuyNotice,
        buyer_submit_item_order_extra: this.handleLoginNoticeBuyNotice,
        delivering_buyer_submit_order_extra: this.handleLoginNoticeBuyNotice,
        buyer_transfer_not_enabled: this.handleLoginNoticeBuyNotice,
        buyer_not_close_game: this.handleLoginNoticeBuyNotice,
        cfb25_player_info_error: this.handleLoginNoticeBuyNotice,
        cfb25_not_player_info: this.handleLoginNoticeBuyNotice,
        grant_repurchase_coupon: this.handleLoginNoticeDialogCoupon,
        old_user_coupon: this.handleLoginNoticeExistUserCoupon,
      }[scene];
      this.dialogCouponRepurchase = null;
      handler(loginNoticeInfo);
    },
    handleGoCouponList() {
      this.$router.push({ name: 'Account.Coupon' });
      this.handleCloseDialogCoupon();
    },
    handleCloseDialogCoupon() {
      this.dialogCouponRepurchase = null;
    },
    regShareCoupon() {
      const key = 'SHARE_TOKEN';
      // 登录了，主动进行删除share_token，避免反复轮循显示
      SessionStorage.remove(key);
      LocalStorage.remove(key);
      // 主动关闭注册的弹窗
      this.hasModalRegCopuon = false;
      // 主动触发轮循获取优惠券提醒，因优惠券延迟发放，这里延迟个3s获取消息通知
      setTimeout(() => {
        this.$store.dispatch('fetchMessageNotification');
      }, 3 * 1000);
    },
    handleModalRegCouponClose() {
      if (this.guideRegisterCouponResolve) {
        this.guideRegisterCouponResolve();
        this.guideRegisterCouponResolve = null;
      }
    },
    handleModalRegCouponHide() {
      if (this.guideRegisterCouponReject) {
        this.guideRegisterCouponReject();
        this.guideRegisterCouponReject = null;
      }
    },
    async getSightseerMsg() {
      this.$store.dispatch('getSightseerMsg');
    },
  },
  mounted() {
    document.addEventListener('swUpdated', this.updateAvailable);
    // 登录用户延迟3s额外加载一次未读消息
    // 用于一些登弹信息 需要依赖登录态请求接口后触发
    if (this.loginStatus === LoginStatus.logined) {
      setTimeout(() => {
        this.$store.dispatch('fetchMessageNotification').then(() => {
          if (this.isShowLoginNotice) {
            this.isloginNoticeId = this.loginNotice.id;
            this.handleLoginNotice();
          }
        }).catch(() => {});
      }, 3 * 1e3);
    }
    const timers = [];
    if (!this.isInBuffApp) {
      const debouncePollingFn1 = debounce(() => {
        this.$store.dispatch('fetchMessageAnnouncement', {
          game: this.announcementGame || 'main',
        }).catch(() => {});
        if (this.loginStatus === LoginStatus.logined) {
          this.$store.dispatch('fetchMessageNotification').then(() => {
            if (this.isShowLoginNotice) {
              this.isloginNoticeId = this.loginNotice.id;
              this.handleLoginNotice();
            }
          }).catch(() => {});
          this.$store.dispatch('fetchAssetBrief').catch(() => {});
          this.$store.dispatch('getFeedbackUnread').catch(() => {});
        }
      }, 50);
      const debouncePollingFn2 = debounce(() => {
        if (this.loginStatus === LoginStatus.logined) {
          this.$store.dispatch('updateUserInfo');
        }
      }, 50);
      timers.push(window.setInterval(debouncePollingFn1, 10 * 1e3));
      timers.push(window.setInterval(debouncePollingFn2, 600 * 1e3));
    }
    this.timers = timers;
    startupRunner.register(TASK.GuideInstallPwaCoupon, () => {
      const installCouponClaimed = LocalStorage.get('install-coupon-claimed');
      if (this.isInStandalone && !installCouponClaimed) {
        this.handleClaimInstallCoupon(true);
        return new Promise((resolve) => {
          this.showInstallCouponResolve = resolve;
        });
      }
      return Promise.reject();
    });
    /* 未登录的时候，弹窗提示注册, 仅进入时才判断，后续跳转不再受理了
     1、这一期里，pwa环境中不需要显示这个注册弹窗，后面再看怎么和pwa弹窗结合处理
     */
    startupRunner.register(TASK.GuideRegisterCoupon, () => {
      if (isGoogleBot()) return Promise.reject();
      if (!(this.userInfo && this.userInfo.uid) && !this.isInStandalone) {
        this.hasModalRegCopuon = true;
        return new Promise((resolve, reject) => {
          this.guideRegisterCouponResolve = resolve;
          this.guideRegisterCouponReject = reject;
        });
      }
      return Promise.reject();
    });
    if (this.loginStatus !== LoginStatus.logined) {
      this.setGuid();
      this.getSightseerMsg();
      this.timerSightseer = window.setInterval(() => {
        this.getSightseerMsg();
      }, 10 * 1e3);
    }
  },
  beforeDestroy() {
    this.timers.forEach((timer) => window.clearInterval(timer));
    window.clearInterval(this.timerSightseer);
  },
};
