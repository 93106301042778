import { render, staticRenderFns } from "./ModalPrefer.vue?vue&type=template&id=e8e6b646&scoped=true"
import script from "./ModalPrefer.vue?vue&type=script&lang=js"
export * from "./ModalPrefer.vue?vue&type=script&lang=js"
import style0 from "./ModalPrefer.vue?vue&type=style&index=0&id=e8e6b646&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8e6b646",
  null
  
)

export default component.exports