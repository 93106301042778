<template>
  <div>
    <transition name="slidein-im">
      <div v-if="loaded && !isMobile" v-show="show"
        class="dialog" :style="mergedWrapStyle">
        <MessageChat
          :to-seller-id="toSellerId"
          :active-tab="activeTab"
          :extra-info="extraInfo"
          :send-text="sendText"
          :show="show"
          @changeTab="handleChangeTab"
          @close="handleClose" />
      </div>
    </transition>
    <w-modal
      v-if="isMobile"
      :show="show"
      :close-on-click-modal="false"
      :color="'rgba(0, 0, 0, 0)'"
      :class="extraInfo.class_name"
      @close="handleClose">
      <MessageChat
        :to-seller-id="toSellerId"
        :active-tab="activeTab"
        :extra-info="extraInfo"
        :send-text="sendText"
        @changeTab="handleChangeTab"
        @close="handleClose" />
    </w-modal>
  </div>
</template>

<script>
import { isMobile } from '@/utils';
import Loading from '@/components/Loading.vue';
import PopupManager from '@/packages/popup-manager';

export default {
  components: {
    MessageChat: () => ({
      component: import('./MessageChat.vue'),
      loading: Loading,
    }),
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 375,
    },
    activeTab: {
      type: String,
      default: 'Messages',
    },
    toSellerId: {
      type: String,
      default: '',
    },
    extraInfo: {
      type: Object,
      default: () => ({}),
    },
    sendText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loaded: false,
      isMobile: isMobile(),
      zIndex: PopupManager.nextZIndex(),
    };
  },
  computed: {
    mergedWrapStyle() {
      return `width:${this.width}px; z-index:${this.zIndex};`;
    },
  },
  watch: {
    show(val) {
      this.zIndex = PopupManager.nextZIndex();
      if (val) this.loaded = true;
    },
  },
  methods: {
    handleChangeTab(tab) {
      this.$emit('changeTab', tab);
    },
    handleClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="less" scoped>
  .dialog {
    position: fixed;
    right: 32px;
    bottom: 155px;
    z-index: 10000;
    box-shadow: var(--shadow-3);
    height: 550px;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #e8e9eb;
    @media(max-height: 800px) {
      height: 450px;
    }
    @media(max-height: 680px) {
      height: 350px;
    }
  }
  .mobile-dialog {
    position: fixed;
    top: 40px;
    left: 0;
    width: 100%;
    height: calc(100% - 40px);
    z-index: 99998;
  }
  /* 云真机中需要使用im，不需要全屏 */
  .dialog-cloud-device{
    :deep(.modal-mask) {
      background-color: rgba(0, 0, 0, 0.56) !important;
    }
    :deep(.message-chat) {
      border-radius: 4px 4px 0 0;
      height: calc(100% - 150px);
      margin-top: auto;
    }
    @media screen and (orientation: landscape) {
      :deep(.message-chat) {
        height: 80%;
      }
    }
  }
</style>
