import Vue from 'vue';
import { i18n } from '@/utils/i18n';
import Dialog from './dialog.vue';
import DialogNew from './dialog.new.vue';

const DialogCtor = Vue.extend(Dialog);
const DialogNewCtor = Vue.extend(DialogNew);

const DialogCreator = () => ({
  isNew, ...payload
} = {}) => {
  let instance;
  if (isNew) {
    const {
      needTitle = true,
      title,
      titleIcon,
      content,
      size,
      closeBtn,
      cancelBtn,
      confirmBtn,
      onConfirm,
      onCancel,
      actionsDirection,
      confirmText,
      cancelText,
      maxHeight,
      theme, // 提供一套样式让外面先简单兼容一下，后面再看怎么搞进来
    } = payload;
    instance = new DialogNewCtor({
      el: document.createElement('div'),
      i18n,
    });
    instance.title = title || (needTitle ? i18n.t('global.notice') : '');
    instance.titleIcon = titleIcon;
    instance.content = content;
    instance.size = size;
    instance.closeBtn = closeBtn;
    instance.cancelBtn = cancelBtn;
    instance.confirmBtn = confirmBtn;
    instance.confirmText = confirmText;
    instance.cancelText = cancelText;
    instance.onConfirm = onConfirm;
    instance.onCancel = onCancel;
    instance.actionsDirection = actionsDirection;
    instance.maxHeight = maxHeight;
    instance.theme = theme;
    instance.show = true;
  } else {
    const {
      needTitle = true,
      title,
      content,
      tips,
      needCloseIcon = true,
      needConfirmBtn,
      needCancelBtn,
      confirmText,
      cancelText,
      onConfirm,
      onCancel,
      confirmType,
      width,
      maxHeight,
      countdown,
      theme = '',
    } = payload;
    instance = new DialogCtor({
      el: document.createElement('div'),
      i18n,
    });
    instance.title = title || (needTitle ? i18n.t('global.notice') : '');
    instance.content = content;
    instance.tips = tips;
    instance.needCloseIcon = needCloseIcon;
    instance.needConfirmBtn = needConfirmBtn;
    instance.needCancelBtn = needCancelBtn;
    instance.confirmText = confirmText;
    instance.cancelText = cancelText;
    instance.onConfirm = onConfirm;
    instance.onCancel = onCancel;
    instance.confirmType = confirmType;
    instance.width = width;
    instance.maxHeight = maxHeight;
    instance.show = true;
    instance.countdown = countdown;
    if (theme) {
      instance.theme = theme;
    }
  }
  instance.promise = new Promise((resolve) => {
    instance.$on('close', (state) => {
      instance.show = false;
      resolve({ state });
    });
  });

  instance.promise.instance = instance;

  return instance.promise;
};

export default DialogCreator;
