import Axios from '@/utils/axios.ext';
import api from '@/api';

const state = {};

const getters = {};
const mutations = {};
const actions = {
  postDeviceApply(_, payload) {
    return Axios({
      method: 'post',
      url: api.postDeviceApply(),
      data: payload,
    }).then((resp) => resp.data);
  },
  postSellerDeviceApply(_, payload) {
    return Axios({
      method: 'post',
      url: api.postSellerDeviceApply(),
      data: payload,
    }).then((resp) => resp.data);
  },
  postSellerReleaseDevice(_, payload) {
    return Axios({
      method: 'post',
      url: api.postSellerReleaseDevice(),
      data: payload,
    }).then((resp) => resp.data);
  },
  getSellerOrderState(_, { ids }) {
    return Axios({
      method: 'get',
      url: api.getSellerOrderState(),
      params: {
        bill_order_ids: ids,
      },
    }).then((resp) => resp.data);
  },
};

const module = {
  state,
  getters,
  mutations,
  actions,
};

export default module;
