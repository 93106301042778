<template>
  <w-dialog-new
    :title="title"
    :confirm-btn="null"
    :cancel-btn="null"
    size="xl"
    :show="show" @close="handerClose">
    <div class="video-view">
      <video autoplay muted controls width="100%">
        <source :src="url" />
        Your browser does not support the video tag.
      </video>
    </div>
  </w-dialog-new>
</template>

<script>
export default {
  props: {
    show: Boolean,
    title: String,
    url: String,
  },
  data() {
    return {
      width: 900,
    };
  },
  created() {
    if (window.screen.width < 620) {
      this.width = 320;
    } else {
      this.width = 900;
    }
  },
  methods: {
    handerClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="less" scoped>
.video-view {
  padding: 0 20px 20px;
}
</style>
