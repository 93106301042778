import ABTest from '@/utils/abtest/core';
import ABTestComponent from './ABTestComponent.vue';

const abtest = new ABTest();

export default {
  LAYER: ABTest.LAYER,
  init(options) {
    const {
      experiments, storage, storageKey, did,
    } = options;
    abtest.init({
      experiments, storage, storageKey, did,
    });
  },
  setLayer(layer, value) {
    abtest.setLayer(layer, value);
  },
  asyncGetABTestComponent: ({
    experimentName, variants, loading,
  }) => () => ({
    component: new Promise((resolve) => {
      function onInited() {
        let variant;
        try {
          variant = abtest.getVariant(experimentName);
        } catch (err) { /** */ }
        let component = null;
        if (variants[variant] !== null) {
          const variantComponent = variants[variant] ?? variants.default;
          component = (variantComponent ? variantComponent() : null);
        }
        resolve(component || { render: () => null });
      }
      if (abtest._init) onInited();
      else abtest.on('init', onInited);
    }),
    loading,
    delay: 0,
  }),
  plugin: {
    install(VueInstance) {
      VueInstance.prototype.$abtest = abtest;
      const getVariant = abtest.getVariant.bind(abtest);
      VueInstance.prototype.$abtest.getVariant = (experimentName) => {
        try {
          return getVariant(experimentName);
        } catch (err) {
          return '';
        }
      };
      // eslint-disable-next-line vue/component-definition-name-casing
      VueInstance.component('ABTestComponent', ABTestComponent);
    },
  },
};
