<template>
  <div class="coin-widget-box">
    <!-- 悬浮球打开的状态需要显示悬浮球 -->
    <div v-if="isShowWidgetIm || isShowMessageChat" class="coin-widget"
      :class="{'is-open-chat': isShowMessageChat && !isMobile,'is-hidden': isHidden && isMobile}"
      :style="{zIndex: zIndex}"
      @click="handleToggleChat">
      <!-- 2024.12.3 添加购物车后,固定文案，不使用weblate -->
      <p v-if="!isShowMessageChat && !isMobile" class="font14">24/7</p>
      <i class="coin-widget-icon" :class="{'is-chat': isShowMessageChat && !isMobile}"></i>
      <div v-if="unreads" class="message-tip">{{ unreads > 99 ? '99+' : unreads }}</div>
    </div>
    <MessageChatDialog
      :to-seller-id="currentSeller?.userId || seller?.userId"
      :active-tab="activeTab"
      :show="isShowMessageChat"
      :extra-info="currentSeller?.extraInfo || seller?.extraInfo"
      :send-text="currentSeller?.sendText || seller?.sendText"
      @close="handleCloseMessage"
      @changeTab="handleChangeTab" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import bus from '@/utils/bus';
import { LoginStatus } from '@/enums';
import { isMobile } from '@/utils';
import MessageChatDialog from '@/views/components/MessageChat/MessageChatDialog.vue';
import PopupManager from '@/packages/popup-manager';

const WIDGET_HIDDEN_DELAY = 10e3;

/**
 * 点联系卖家的时候一定去联系的卖家
 * 点IM首页发送的时候一定去页面的卖家
 * 点IM悬浮球的时候走优先级
 *   1. 有未读消息去对应卖家或者列表页
 *   2. 当前有记录配置的页面卖家信息
 *      1. 上次收起的页面是同一个卖家，保持不变
 *      2. 其他情况返回首页
 *   3. 上次收起的页面
 *
 * seller: 记录配置的页面的卖家信息
 * currentSeller: 当前联系的卖家信息
 *
 *  */

export default {
  components: {
    MessageChatDialog,
  },
  props: {
    isShowWidgetIm: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isMobile: isMobile(),
      zIndex: PopupManager.nextZIndex(),
      fixZIndex: false, // 是否固定zIndex
      isHidden: false, // 移动端是否收起

      isShowMessageChat: false,
      // 当前tab
      // [Index,Messages,Question,AiSupport,Conversation]
      activeTab: 'Index',
      // userId, extraInfo, sendText
      seller: null,
      currentSeller: null,
      extraInfo: {}, // 携带参数
      sendText: '', // 发送内容
      imLogInfo: {}, // 日志信息
    };
  },
  computed: {
    ...mapGetters(['loginStatus', 'notifications', 'userInfo', 'sightseerUnread', 'sightseerToken', 'allImSeller']),
    unreads() {
      let imNum = 0;
      if (this.loginStatus === LoginStatus.logined) {
        imNum = this.notifications?.unread_im_message?.total ?? 0;
      } else {
        imNum = this.sightseerUnread?.unread_count;
      }
      return imNum;
    },
  },
  watch: {
    isShowMessageChat(val) {
      // 打开弹窗时，置顶IM弹窗
      if (val || this.fixZIndex) {
        this.zIndex = PopupManager.nextZIndex();
      } else {
        this.zIndex = null;
      }
    },
    $route: {
      handler() {
        if (this.isMobile) {
          this.handleCloseMessage();
          this.handleShowWidget();
        }
      },
      immediate: true,
    },
    unreads(num) {
      if (!num) {
        this.handleShowWidget();
      } else {
        this.isHidden = false;
      }
    },
  },
  mounted() {
    this.setSeller = (seller) => {
      this.seller = this.getSeller(seller);
    };
    bus.$on('im.seller.set', this.setSeller);
    this.openMessage = (isOpen = true, seller) => {
      this.isShowMessageChat = isOpen;
      if (seller !== undefined) {
        const _seller = this.getSeller(seller);
        if (this.isValidSeller(_seller)) {
          this.currentSeller = _seller;
          this.activeTab = 'Conversation';
        } else {
          this.activeTab = 'AiSupport';
        }
      }
    };
    // 全局修改IM弹窗显示状态
    // 传了 seller 参数的固定去到对应卖家聊天
    bus.$on('im.open', this.openMessage);
    this.clearExtraInfo = () => {
      if (this.currentSeller) {
        this.currentSeller.extraInfo = {};
        this.currentSeller.sendText = '';
      }
    };
    bus.$on('im.extra.clear', this.clearExtraInfo);
    this.setIMLogInfo = (info = {}) => {
      this.imLogInfo = info;
    };
    bus.$on('setIMLogInfo', this.setIMLogInfo);
    bus.$on('im.hide', this.hideChat);
    // 新增一个im.fix在最顶层的样式吧
    bus.$on('im.fixZIndex', this.setFixZIndex);
  },
  beforeDestroy() {
    bus.$off('im.seller.set', this.setSeller);
    bus.$off('im.open', this.openMessage);
    bus.$off('im.extra.clear', this.clearExtraInfo);
    bus.$off('setIMLogInfo', this.setIMLogInfo);
    bus.$off('im.hide', this.hideChat);
    bus.$off('im.fixZIndex', this.setFixZIndex);
  },
  methods: {
    setFixZIndex(bool) {
      this.fixZIndex = !!bool;
      // 关闭时要进行清除设置
      if (!this.fixZIndex && !this.isShowMessageChat) {
        this.zIndex = null;
      }
    },
    getSeller(seller) {
      if (!seller) return null;
      const _seller = typeof seller === 'string' ? { userId: seller } : seller;
      const { userId, extraInfo = {}, sendText = '' } = _seller;
      return { userId, extraInfo, sendText };
    },
    isValidSeller(seller) {
      const _seller = this.getSeller(seller);
      if (!_seller) return false;
      return this.allImSeller.includes(_seller.userId);
    },
    handleShowWidget() {
      if (this.unreads) {
        this.isHidden = false;
        return;
      }
      if (this.timerHidden) window.clearTimeout(this.timerHidden);
      this.isHidden = false;
      this.timerHidden = window.setTimeout(() => {
        this.isHidden = true;
      }, WIDGET_HIDDEN_DELAY);
    },
    handleCloseMessage() {
      this.isShowMessageChat = false;
    },
    handleChangeTab(name) {
      this.activeTab = name;
      if (name !== 'Conversation') {
        // 离开会话时自动清掉当前卖家
        this.currentSeller = null;
      } else if (!this.currentSeller) {
        // 进入会话时没有当前卖家
        if (!this.seller || !this.isValidSeller(this.seller)) {
          // 没有配置页面卖家进入AI助手
          this.activeTab = 'AiSupport';
        } else {
          // 有配置页面卖家自动指向配置的卖家
          this.currentSeller = this.seller;
        }
      }
    },
    // 用于点击悬浮球时，初始化聊天
    initToggleShowChat() {
      // 1. 有未读消息
      let seller;
      let num;
      let ids;
      if (this.loginStatus === LoginStatus.logined) {
        num = this.notifications?.unread_im_message?.total ?? 0;
        const infos = this.notifications?.unread_im_message?.unread_conversation_infos || [];
        ids = infos.map(({ conversation_users: users = [] }) => {
          const matched = users.find(({ uid }) => uid !== this.userInfo.uid);
          return matched?.uid || '';
        });
      } else {
        num = this.sightseerUnread?.unread_count;
        const infos = this.sightseerUnread.unread_conversation_infos;
        ids = infos.map(({ conversation_users: users = [] }) => {
          const matched = users.find(({ vistor_token: token }) => token !== this.sightseerToken);
          return matched?.uid || '';
        });
      }
      if (num > 0) {
        // 未读会话不止一个时进入会话列表
        if (ids.length !== 1) {
          this.handleChangeTab('Messages');
          return;
        }
        // 只有一个时直接进入会话
        seller = this.getSeller(ids[0]);
        if (seller) {
          this.currentSeller = seller;
          this.handleChangeTab('Conversation');
          return;
        }
      }
      // 2. 当前有记录配置的页面卖家信息
      if (this.seller) {
        // 1. 上次收起的页面是同一个卖家，保持不变
        if (this.activeTab === 'Conversation' && this.currentSeller?.userId === this.seller?.userId) {
          return;
        }
        // 2. 其他情况返回首页
        this.handleChangeTab('Index');
      }
    },
    handleToggleChat() {
      if (!this.isShowMessageChat) {
        this.initToggleShowChat();
        this.isShowMessageChat = true;
        bus.$emit('hideShoppingCart');
      } else {
        this.isShowMessageChat = false;
      }
      bus.$emit('setCoinLog', { name: 'c2c_gamecoin_chat_click' });
      if (this.imLogInfo?.name) {
        this.$logger(this.imLogInfo?.name, this.imLogInfo?.params);
      }
      if (this.isMobile) {
        this.$logger('imballmobileclick');
      }
      if (this.isMobile && this.isShowMessageChat) {
        this.$logger('imballmobileview');
      }
    },
    hideChat() {
      this.isShowMessageChat = false;
    },
  },
};
</script>

<style lang="less" scoped>
.coin-widget-box {
  background: var(--bg-bg1);
  border-radius: 32px;
}
.coin-widget {
  position: fixed;
  right: 32px;
  bottom: 80px;
  display: flex;
  align-items: center;
  margin-bottom: calc(env(safe-area-inset-bottom));
  padding: 8px 8px 8px 12px;
  // height: 64px;
  border-radius: 32px;
  background: var(--bg-bg1);
  border: 1px solid #edeef2;
  box-shadow: var(--shadow-3);
  cursor: pointer;
  // max-width: 160px;
  z-index: 9;
  transform: translate(0, 0);
  opacity: 1;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
  &.is-open-chat {
    width: 64px;
    padding: 0;
    border-radius: 50%;
  }
  &.is-hidden {
    transform: translate(50%);
    opacity: 0.5;
  }
  &:hover { background: var(--nc-g10); }
  &:active { background: var(--nc-g9); }
  @media(max-width: 750px) {
    right: 0;
    bottom: 140px;
    padding: 8px;
    border-radius: 32px 0 0 32px;
  }
  .message-tip {
    position: absolute;
    top: -8px;
    right: 6px;
    min-width: 16px;
    justify-content: center;
    align-items: center;
    height: 16px;
    background: var(--fu-red1);
    border-radius: 16px;
    padding: 0% 4px;
    z-index: 998;
    color: var(--fu-white1);
    font-size: 14px;
    line-height: 16px;
  }
  p {
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    .fontBold();
  }

  &-icon {
    flex: 0 0 auto;
    margin-left: 4px;
    width: 48px; height: 48px;
    background: center/contain url("~@/assets/images/icon_support.png") no-repeat;
    &.is-chat {
      width: 64px; height: 64px;
      margin-left: 0;
      background: center/contain url("~@/assets/images/im_chat_close.png") no-repeat;
    }
    @media(max-width: 750px) {
      margin-left: 0;
    }
  }
}
</style>
