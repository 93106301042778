import { isTiktok } from '@/utils';
import { TASK, startupRunner } from '@/utils/startup';
import { ProfileGuideEnum } from '@/enums';

export default {
  data() {
    return {
      showInstallSidebtnActive: false,
    };
  },
  methods: {
    handleCollapse(e) {
      if (this.$refs.installPrompt && this.$refs.installPrompt.contains(e.target)) return;
      this.showInstallSidebtnActive = false;
      window.removeEventListener('touchstart', this.handleCollapse);
    },
    bindCollapseEvent() {
      window.addEventListener('touchstart', this.handleCollapse);
    },
    async handleOpenInBrowser() {
      if (!this.showInstallSidebtnActive) {
        this.$logger('c2c_tiktok_browser_click');
        this.bindCollapseEvent();
        this.showInstallSidebtnActive = true;
      } else {
        this.$logger('c2c_tiktok_browser_tips');
        this.$guide(ProfileGuideEnum.openInBrowserTiktok);
      }
    },
    async handleBookmark() {
      if (!this.showInstallSidebtnActive) {
        this.$logger('c2c_bookmark_pop_click');
        this.bindCollapseEvent();
        this.showInstallSidebtnActive = true;
      } else {
        this.$logger('c2c_bookmark_detail_click');
        this.$_handleInstall();
      }
    },
    /** 引导tiktok登录
     * 1、未登录且是在titktok内嵌页
     */
    guideTiktokLogin() {
      if (!this.isTiktok) return;
      startupRunner.register(TASK.LoginWithTiktok, () => {
        const { userInfo } = this.$store.getters;
        if (userInfo || startupRunner.checkTasksDone([TASK.GuideRegisterCoupon])) {
          return Promise.reject();
        }
        this.$logger('c2c_tiktok_login_view');
        return new Promise((resolve, reject) => {
          this.$dialog({
            isNew: true,
            closeBtn: null,
            title: this.$t('global.guide_login_tiktok.title'),
            content: this.$t('global.guide_login_tiktok.message'),
            confirmText: this.$t('global.guide_login_tiktok.confirm'),
            cancelText: this.$t('global.cancel'),
            onConfirm: () => {
              this.$logger('c2c_tiktok_login_authorize_click');
              return this.$reg(ProfileGuideEnum.signinTiktok, {});
            },
            onCancel: () => {
              this.$logger('c2c_tiktok_login_cancel_click');
              reject();
            },
          });
        });
      });
    },
    updateInnerWidth() {
      document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`);
    },
  },
  computed: {
    isTiktok() {
      const { name } = this.$route;
      const isActiveRoute = ['Index', 'Topup', 'List', 'Detail', 'My']
        .includes(name);
      return isActiveRoute && isTiktok();
    },
    isIOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent);
    },
    deferredInstallPrompt() {
      return this.$store.state.deferredInstallPrompt;
    },
    isInStandalone() {
      return this.$store.state.isInStandalone;
    },
    showInstallSidebtn() {
      const { name } = this.$route;
      const isActiveRoute = []
        .includes(name);
      return !this.isInStandalone && isActiveRoute && (this.isIOS || this.deferredInstallPrompt);
    },
  },
  mounted() {
    this.guideTiktokLogin();
  },
  created() {
    window.addEventListener('resize', this.updateInnerWidth);
    this.$nextTick(() => {
      this.updateInnerWidth();
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateInnerWidth);
  },
};
