const COUPON_QUERY_TYPE = {
  INUSE: 'inuse', USED: 'used', EXPIRED: 'expired',
};

const COUPON_TYPE = {
  sale: 1, // 折扣券
  discount: 2, // 满减券
};

export default COUPON_QUERY_TYPE;
export { COUPON_TYPE };
