<template>
  <div id="app" :class="{'inBuffApp': isInBuffApp}">
    <div class="mobile-nav-wrap" @touchmove="(e) => {e.preventDefault();}">
      <Nav v-if="!isInBuffApp">
        <div v-if="announcement" class="announcement">
          <GlobalNotice
            :content="announcement.message"
            :interactive="!!announcement.link"
            @content-click="handleContentClick"
            @close="handleCloseAnnouncement" />
        </div>
      </Nav>
    </div>
    <div class="main">
      <router-view v-if="!reloading" />
    </div>
    <AppBar />
    <div
      v-if="isTiktok"
      ref="installPrompt"
      class="mobile-install-btn tiktok"
      :class="{'is-active': showInstallSidebtnActive}"
      @click="handleOpenInBrowser">
      <i class="mobile-install-btn-icon"></i>
      <p>{{ $t('bookmark.open_browser') }}</p>
    </div>
    <div
      v-else-if="showInstallSidebtn && isIOS && !appStatus.inRegCoupon"
      ref="installPrompt"
      class="mobile-install-btn bookmark"
      :class="{'is-active': showInstallSidebtnActive}"
      @click="handleBookmark">
      <i class="mobile-install-btn-icon"></i>
      <p v-html="$t('bookmark.add_bookmark_bonus')"></p>
    </div>
    <div
      v-else-if="showInstallSidebtn && !isIOS && !appStatus.inRegCoupon"
      ref="installPrompt"
      class="mobile-install-btn"
      :class="{'is-active': showInstallSidebtnActive}"
      @click="handleInstallSidebtn">
      <i class="mobile-install-btn-icon"></i>
    </div>
    <w-drawer
      :show="!!suggestLanguage"
      :title="suggestLanguage?.title || ''"
      :mask="false"
      confirm-btn :confirm-text="suggestLanguage?.confirmText"
      cancel-btn :cancel-text="suggestLanguage?.cancelText"
      @confirm="handleSuggestLanguageConfirm"
      @cancel="handleSuggestLanguageCancel"
      @close="handleSuggestLanguageCancel">
      <div class="mobile-drawer-content"
        v-html="suggestLanguage?.content"></div>
    </w-drawer>
    <w-drawer
      :z-index="9000"
      :show="!!updateExists"
      :title="$t('global.update_prompt_title')"
      :mask="false"
      confirm-btn :confirm-text="$t('global.update_prompt_btn')"
      @confirm="refreshApp"
      @close="cancelUpdate">
      <div class="mobile-drawer-content">
        <div class="mobile-install-tips">
          <img src="~@/assets/images/logo.png" alt="LootBar logo" />
          <div>
            <p class="font16r-min tips">{{ $t('global.update_prompt_content') }}</p>
          </div>
        </div>
      </div>
    </w-drawer>

    <w-modal :show="showInstallCoupon">
      <div class="install-coupon-dialog">
        <w-button class="install-coupon-dialog-close"
          icon name="icon_close" size="m"
          @click="handleClaimInstallCouponClose" />
        <div class="font16 install-coupon-dialog-header">
          <p v-html="installCouponInfo?.title"></p>
        </div>
        <div class="font16 install-coupon-dialog-content">{{ installCouponInfo?.desc }}</div>
        <div class="install-coupon-dialog-action">
          <w-button
            type="primary"
            @click="handleInstallCouponConfirm(installCouponInfo?.routeName)">{{
            installCouponInfo?.btnText }}</w-button>
        </div>
      </div>
    </w-modal>
    <!-- 受邀注册的用户提示获取优惠券, 未登录才可提示-->
    <ModalRegCopuon
      :show-modal="hasModalRegCopuon"
      @setAppStatus="setAppStatus"
      @close="handleModalRegCouponClose"
      @hide="handleModalRegCouponHide" />
    <DialogCoupon
      :show="showRepurchaseDialogCoupon"
      :desc="dialogCouponRepurchase?.desc"
      :coupons="dialogCouponRepurchase?.coupons"
      :confirm-btn-text="$t('$coupon.check_coupons')"
      :hand-show="true"
      :extra="dialogCouponRepurchase?.extra"
      @confirm="handleGoCouponList"
      @cancel="handleCloseDialogCoupon"
      @close="handleCloseDialogCoupon" />
    <DialogCoupon
      :show="dialogCouponGame?.show"
      :desc="dialogCouponGame?.desc"
      :bonus-text="dialogCouponGame?.bonusText"
      :coupons="dialogCouponGame?.coupons"
      :coupon-bg="dialogCouponGame?.couponBg"
      :confirm-btn-text="$t('new_coupon.collet_coupon')"
      :hand-show="true"
      :extra="dialogCouponGame?.extra"
      @confirm="handleConfirmDialogCouponGame"
      @cancel="dialogCouponGame.show = false"
      @close="dialogCouponGame.show = false" />
    <WidgetIm :is-show-widget-im="showWidgetIm" />
  </div>
</template>

<script>
import Nav from '@/components/Nav/Nav.mobile.c2c.vue';
import AppBar from '@/components/AppBar/AppBar.mobile.c2c.vue';
import { getEndOfTime, isGoogleBot } from '@/utils';
import localStorage from '@/utils/local-storage';
import { TASK, startupRunner } from '@/utils/startup';

import mixin from './App.mixin.c2c';
import mixinMobile from './App.mobile.mixin';

export default {
  components: { Nav, AppBar },
  mixins: [mixin, mixinMobile],
  data() {
    return {
      innerHeight: window.innerHeight,

      noMorePromptAddBookmark: true,
      installPromptClosed: false,
    };
  },
  computed: {
    showInstallPrompt() {
      const { isInStandalone } = this.$store.state;
      const { name } = this.$route;
      const isActiveRoute = ['Index', 'Topup', 'List', 'Detail']
        .includes(name);
      return !isInStandalone
        && !this.isIOS
        && !this.noMorePromptAddBookmark
        && !this.installPromptClosed
        && isActiveRoute
        && this.deferredInstallPrompt;
    },
    showWidgetIm() {
      const isPwa = this.appStatus.inRegCoupon ? this.appStatus.inRegCoupon
        : !this.showInstallSidebtn;
      if (this.isInBuffApp) return false;
      return this.userInfo?.user_type !== 2 && isPwa && this.isShowWidgetIm;
    },
    isShowWidgetIm() {
      const { name } = this.$route;
      const isActiveRoute = [
        // 首页，充值，游戏币，游戏道具，游戏列表，游戏详情
        'Index', 'Topup', 'Coin', 'Gameitem', 'List', 'Detail',
        // 我的，订单列表，订单详情
        'My', 'Account.Buy', 'Account.Buy.Order',
      ].includes(name);
      return isActiveRoute;
    },
  },
  watch: {
    showInstallPrompt(val) {
      if (val) {
        this.$logger('c2c_pwa_install_popup');
      }
    },
  },
  created() {
    startupRunner.register(TASK.GuideInstallPwa, () => {
      if (isGoogleBot()) return Promise.reject();
      // if (startupRunner.checkTasksDone([TASK.CookiePolicy, TASK.GuideRegisterCoupon])) {
      if (startupRunner.checkTasksDone([TASK.GuideRegisterCoupon])) {
        return Promise.reject();
      }
      const map = localStorage.get('no-more-prompts') || {};
      const cur = new Date().getTime();
      this.noMorePromptAddBookmark = cur <= map.addBookmark;
      return Promise.resolve();
    });
    // window.setTimeout(() => {
    //   const map = localStorage.get('no-more-prompts') || {};
    //   const cur = new Date().getTime();
    //   this.noMorePromptAddBookmark = cur <= map.addBookmark;
    // }, 1000);
  },
  methods: {
    handleNoMorePromptInstall() {
      this.$logger('c2c_pwa_install_popup_cancel_click');
      const map = localStorage.get('no-more-prompts') || {};
      // 一周后再次提示
      const offset = 1000 * 60 * 60 * 24 * 7;
      const timestamp = getEndOfTime(new Date().getTime() + offset, 'day');
      map.addBookmark = timestamp;
      localStorage.set('no-more-prompts', map);
      this.noMorePromptAddBookmark = true;
    },
    handleInstall() {
      this.$logger('c2c_pwa_install_popup_install_click');
      this.installPromptClosed = true;
      this.$_handleInstall();
    },
    handleInstallSidebtn() {
      this.$logger('c2c_pwa_install_click');
      this.installPromptClosed = true;
      this.$_handleInstall();
    },
  },
};
</script>

<style lang="less" scoped>
#app {
  min-height: var(--app-height);
  background-color: @tint_dark_2;
}
.main {
  flex: 1 1 0;
  overflow: hidden;
  :deep(.page-tab-bar) {
    position: fixed;
    top: var(--nav-height);
  }
}

.mobile-install-btn {
  padding: 4px 8px 4px 12px;
  display: flex;
  align-items: center;
  position: fixed;
  left: 100%;
  bottom: 192px;
  z-index: 999;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
  border-radius: 20px 0 0 20px;
  background-color: #fff;
  transform: translate(-48px, 0);
  white-space: nowrap;
  transition: all 0.2s ease-out;
  p {
    opacity: 0;
    transition: all 0.2s ease-out;
    :deep(b) {
      color: @tint_error;
      font-weight: 500;
    }
  }
  &.is-active {
    transform: translate(-100%, 0);
    p { opacity: 1; }
  }
  &-icon {
    display: inline-block;
    width: 32px;
    height: 32px;
    background: center/contain url('~@/assets/images/icon_mobile_install.png') no-repeat;
  }
  &.bookmark {
    .mobile-install-btn-icon {
      background: center/contain url('~@/assets/images/icon_mobile_bookmark_bonus.png') no-repeat;
    }
  }
  &.tiktok {
    top: 190px;
    bottom: auto;
    .mobile-install-btn-icon {
      background-image: url('~@/assets/images/icon_float_browser.png');
    }
  }
}

.mobile-drawer {
  &-content {
    padding: 8px 16px;
    :deep(span) {
      color: var(--el-bc1);
      font-weight: 600;
    }
  }
}

.mobile-bottom {
  &-wrap {
    position: fixed;
    left: 0; right: 0; bottom: 0;
    z-index: 9999;
    padding: 16px;
    padding-bottom: calc(16px + constant(safe-area-inset-bottom));
    padding-bottom: calc(16px + env(safe-area-inset-bottom));
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.20);
    background-color: @tint_white;
  }
  &-content {
    color: var(--el-gf2);
    :deep(span) {
      color: var(--el-bc1);
      font-weight: 600;
    }
  }
  &-actions {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    & > * {
      flex: 1 1 0;
      height: 48px;
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.mobile-install {
  &-tips {
    display: flex;
    align-items: center;
    img {
      width: 64px; height: 64px;
      margin-right: 12px;
      flex: 0 0 auto;
    }
    .tips {
      color: rgba(#1f2128, 0.6);
      :deep(b) {
        color: @tint_error;
        font-weight: 500;
      }
    }
  }
}

.mobile-cookie {
  &-wrap {
    position: fixed;
    left: 0; right: 0; bottom: 0;
    z-index: 9999;
    padding: 16px;
    padding-bottom: calc(16px + constant(safe-area-inset-bottom));
    padding-bottom: calc(16px + env(safe-area-inset-bottom));
    background-color: rgba(#000, 0.8);
  }
  &-tips {
    .tips {
      line-height: 20px;
      color: #fcfcfc;
      :deep(a) {
        white-space: nowrap;
        color: var(--el-bc0);
        text-decoration: underline;
      }
    }
  }
  &-actions {
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;
    gap: 8px;
    :deep(.w-button) {
      min-width: unset;
      max-width: 100%;
    }
    & > * {
      flex: 1 1 0;
      height: 40px;
      font-size: 16px;

      &:last-child {
        margin-right: 0;
        border: 1px solid #ffd05a;
        font-weight: 600;
      }
    }
  }
}

.install-coupon-dialog {
  width: 320px;
  background-color: #fff;
  border-radius: 4px;
  position: relative;
  &-close {
    position: absolute;
    left: 8px; top: 8px;
  }
  &-header {
    width: 320px; height: 180px;
    margin-top: -30px;
    padding: 56px 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    white-space: pre-wrap;
    background: center/contain url('~@/assets/images/install_coupon_dialog_bg.png') no-repeat;
    :deep(b) {
      color: @tint_error;
      font-weight: 500;
    }
  }
  &-content {
    margin: 20px 16px 12px;
    color: rgba(#1f2128, 0.6);
    text-align: center;
  }
  &-action {
    padding: 8px 16px 12px;
    &>* {
      width: 100%;
      height: 48px;
      line-height: 48px;
      font-size: 16px;
    }
  }
}

:deep(.c-scrollbar__bar) {
  display: none;
}
</style>
