<template>
  <w-dialog-new
    :show="show"
    :title="$t('profile.language_currency')"
    :cancel-btn="null"
    :confirm-text="$t('global.confirm')"
    :loading="confirming"
    @confirm="handleConfirmClick"
    @close="handleClose">
    <div class="prefer">
      <div class="prefer-item">
        <div class="font16r prefer-item-header">{{ $t('profile.language') }}</div>
        <w-select v-model="preferLanguage"
          class="font16r prefer-item-select"
          :title="$t('profile.language')"
          size="l"
          :options="languageOptions"
          :placeholder="$t('profile.language')"
          @click.native="$logger('nav_language_click')"
          @input="languageChange" />
      </div>
      <div class="prefer-item">
        <div class="font16r prefer-item-header">{{ $t('profile.display_currency') }}</div>
        <w-select v-model="preferCurrency"
          class="font16r prefer-item-select"
          :title="$t('profile.display_currency')"
          :options="currencyOptions"
          :placeholder="$t('profile.currency')"
          @click.native="$logger('nav_currency_click')"
          @input="handleInput" />
        <div class="font14r prefer-item-tint">{{ $t('profile.display_currency_tips') }}</div>
      </div>
    </div>
  </w-dialog-new>
</template>

<script>
import { mapGetters } from 'vuex';
import { LoginStatus, TextTypeHreflangMap } from '@/enums';
import localStorage from '@/utils/local-storage';
import bus from '@/utils/bus';

export default {
  provide: {
    theme: 'light',
  },
  props: {
    show: Boolean,
  },
  data() {
    return {
      preferLanguage: this.$store.getters.textType,
      preferCurrency: this.$store.getters.currencyCode,
      preferCurrencyInfo: null,

      confirming: false,
    };
  },
  methods: {
    languageChange(language) {
      // console.log(a, b, c);
      const data = {
        ja: 'nav_language_ja_click',
        en: 'nav_language_en_click',
        zh_Hant: 'nav_language_zhhant_click',
      };
      this.$logger(data[language]);
    },
    handleClose() {
      this.$emit('close');
      this.confirming = false;
    },
    handleConfirm() {
      this.$emit('confirm');
      this.confirming = false;
    },
    async handleConfirmClick() {
      this.confirming = true;
      await Promise.all([
        this.handleSelectPreferLanguage(),
        this.handleSelectPreferCurrency(),
      ])
        .then(() => {
          this.confirming = false;
          this.$Message.success(this.$t('global.success'));
          this.handleConfirm();
          this.$logger('nav_confirm_click');
        });
    },
    async handleSelectPreferLanguage() {
      if (this.preferLanguage === this.textType) return false;
      try {
        if (this.isLogined) {
          await this.$store.dispatch('postPreferLanguage', { language: this.preferLanguage });
        } else {
          await this.$store.dispatch('updateLaunguage', { language: this.preferLanguage });
        }
        if (this.$route.meta && this.$route.meta.title) {
          bus.$emit('nav.title.set', this.$t(this.$route.meta.title));
        }
        // this.$store.dispatch('fetchMessageAnnouncement');
        localStorage.set('select-language', this.preferLanguage);
        const { name, query, hash } = this.$route;
        const { ..._params } = this.$route.params || {};
        const hreflang = TextTypeHreflangMap[this.preferLanguage];
        this.$router.replace({
          name,
          query,
          params: { ..._params, hreflang },
          hash,
        });
      } catch (err) {
        this.$_handleError(err);
        this.preferLanguage = this.textType;
        return false;
      }
      return true;
    },
    async handleSelectPreferCurrency() {
      if (this.preferCurrency === this.currencyCode) return true;
      try {
        if (this.isLogined) {
          const resp = await this.$store.dispatch('postPreferCurrency', { currency: this.preferCurrency });
          Object.keys(resp).forEach((key) => {
            if (key === 'currency') {
              this.userInfo.currency = resp[key];
            } else {
              this.userInfo[`currency_${key}`] = resp[key];
            }
          });
          localStorage.set('select-currency', resp.currency);
        } else {
          const { code } = this.preferCurrencyInfo;
          this.$store.commit('SET_CURRENCY_CODE', code);
        }
      } catch (err) {
        this.$_handleError(err);
        this.preferCurrency = this.currencyCode;
        return false;
      }
      return true;
    },
    handleInput(currency, val) {
      const data = {
        USD: 'nav_currency__usd_click',
        JPY: 'nav_currency__jpy_click',
        TWD: 'nav_currency__twd_click',
      }[currency];
      if (!data) {
        this.$logger(`nav_currency_${this.preferCurrency.toLowerCase()}_click`);
      } else {
        this.$logger(data);
      }
      this.preferCurrencyInfo = val;
    },
  },
  computed: {
    ...mapGetters(['loginStatus', 'textType', 'languages', 'currencies', 'userInfo', 'currencySymbol', 'currencyCode', 'currencyRate']),
    languageOptions() {
      return this.languages;
    },
    isLogined() {
      return this.loginStatus === LoginStatus.logined;
    },
    currencyOptions() {
      const options = this.currencies ?? [
        {
          key: this.currencyCode,
          label: this.currencyCode,
          symbol: this.currencySymbol,
          rate: this.currencyRate,
          precision: this.currencyPrecision,
          separator: this.currencySeparator,
          name: this.currencyName,
        },
      ];
      return options.map((item) => ({
        key: item.key,
        label: `${item.symbol} ${item.label}`,
        rate: item.rate,
        symbol: item.symbol,
        code: item.key,
        precision: item.precision,
        separator: item.separator,
        name: item.name,
      }));
    },
    currency: {
      get() {
        return this.currencyCode;
      },
      set(val) {
        this.preferCurrency = val;
      },
    },
  },
  watch: {
    show(val) {
      if (val) {
        this.$logger('nav_langcurrency_click');
        this.preferLanguage = this.$store.getters.textType;
        this.preferCurrency = this.$store.getters.currencyCode;
      }
    },
  },
};

</script>

<style lang="less" scoped>
.prefer {
  padding: 0 20px;
  &-item {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
    &-header {
      margin-bottom: 16px;
    }
    &-select {
      width: 100%;
      height: 48px;
    }
    &-tint {
      margin-top: 12px;
      color: var(--el-gf3);
    }
  }
}
</style>
