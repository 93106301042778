import Axios from '@/utils/axios.ext';
import api from '@/api';
import LocalStorage from '@/utils/local-storage';
import SessionStorage from '@/utils/session-storage';
import ua from '@/utils/ua';
import native from '@/utils/native.bridge';

const client = ua.os.client || {};

const state = {
  loginHide: LocalStorage.get('login-hide') ?? 0,
  preventLoginNotice: false,
  preventNewCustomerDialog: false,
};

const getters = {
  loginHide: (_state) => _state.loginHide,
  preventLoginNotice: (_state) => _state.preventLoginNotice,
  preventNewCustomerDialog: (_state) => _state.preventNewCustomerDialog,
};
const mutations = {
  SET_LOGIN_HIDE(_state, loginHide) {
    _state.loginHide = loginHide;
  },
  PREVENT_LOGIN_NOTICE(_state, preventLoginNotice) {
    _state.preventLoginNotice = preventLoginNotice;
  },
  PREVENT_NEW_CUSTOMER_DIALOG(_state, preventNewCustomerDialog) {
    _state.preventNewCustomerDialog = preventNewCustomerDialog;
  },
};
const actions = {
  /**
   * 获取游戏列表
   * @param {string} search_str 搜索内容
   */
  getGameList(_, { search, ...rest } = {}) {
    const params = {};
    if (search) {
      params.search_str = search;
    }
    return Axios({
      method: 'get',
      url: api.getGameList(),
      params: {
        ...params,
        ...rest,
      },
      useCache: {
        time: 30 * 60 * 1000,
      },
    }).then((resp) => resp.data);
  },
  getGameListC2C({ getters: _getters }, data = {}) {
    const params = {
      ...(SessionStorage.get('UTM_PARAMS') || {}),
      _l: _getters.textType,
    };
    if (data.service) {
      params.service = data.service;
    }
    const config = {
      method: 'get',
      url: api.getGameListC2C(),
      params: {
        ...params,
      },
    };
    if (data.signal) config.signal = data.signal;
    return Axios(config).then((resp) => resp.data);
  },
  getGameModuleAppC2C(_, data = {}) {
    const config = {
      method: 'get',
      url: api.getGameModuleAppC2C(),
    };
    if (data.signal) config.signal = data.signal;
    return Axios(config).then((resp) => resp.data);
  },
  getGameAppServiceC2C({ getters: _getters }, data = {}) {
    const params = {
      _l: _getters.textType,
    };
    if (data.service) {
      params.service_type = data.service;
    }
    if (data.pageSize) {
      params.page_size = data.pageSize;
    }
    if (data.incoming) {
      params.incoming = 1;
    }
    const config = {
      method: 'get',
      url: api.getGameAppServiceC2C(),
      params: {
        ...params,
        ...(SessionStorage.get('UTM_PARAMS') || {}),
      },
    };
    if (data.signal) config.signal = data.signal;
    return Axios(config).then((resp) => resp.data);
  },
  getGameServiceAnnouncement(_, { game, service }) {
    return Axios({
      method: 'get',
      url: api.getAnnouncement(),
      params: { game },
    }).then((resp) => {
      const announcementInfos = LocalStorage.get('announcement-popup-service') || {};
      const announcementInfo = announcementInfos[`${game}-${service}`];
      const newAnnouncementInfos = { ...announcementInfos };
      // 清理过期通知
      Object.keys(announcementInfos).forEach((key) => {
        const nowDate = new Date().getTime();
        if (nowDate > announcementInfos[key].end_time * 1000) {
          delete newAnnouncementInfos[key];
        }
      });
      const { announcement } = resp.data;
      if (announcement && announcementInfo?.id !== announcement?.id) {
        const nowDate = new Date().valueOf();
        if (nowDate >= announcement.start_time * 1000 && nowDate <= announcement.end_time * 1000) {
          newAnnouncementInfos[`${game}-${service}`] = announcement;
        }
        LocalStorage.set('announcement-popup-service', newAnnouncementInfos);
        return resp.data;
      }
      LocalStorage.set('announcement-popup-service', newAnnouncementInfos);
      return null;
    });
  },
  getLootbarMessages() {
    return Axios({
      method: 'get',
      url: api.getLootbarMessages(),
    }).then((resp) => resp.data);
  },
  getMarketBuyOrderFc24Extra() {
    return Axios({
      method: 'get',
      url: api.getMarketBuyOrderFc24Extra(),
    }).then((resp) => resp.data);
  },
  getC2CRefundReasonList(_, { id } = {}) {
    return Axios({
      method: 'get',
      url: api.getC2CRefundReasonList(),
      params: {
        bill_order_id: id,
      },
    }).then((resp) => resp.data);
  },
  getMarketBuyOrderState(_, { ids } = {}) {
    return Axios({
      method: 'get',
      url: api.getMarketBuyOrderState(),
      params: {
        bill_order_ids: ids,
      },
    }).then((resp) => resp.data);
  },
  getRecentlyBuyNicknames(_, { id }) {
    return Axios({
      method: 'get',
      url: api.getRecentlyBuyNicknames(),
      params: {
        spu_id: id,
      },
    }).then((resp) => resp.data);
  },
  getBuyOrderConfirm(_, {
    id, game,
  } = {}) {
    return Axios({
      method: 'post',
      url: api.getBuyOrderConfirm(),
      data: {
        bill_order_id: id,
        game,
      },
    }).then((resp) => resp.data);
  },
  getInviteLink(_, {
    onlyLink = false,
  } = {}) {
    return Axios({
      method: 'post',
      url: api.getInviteLink(),
      data: {
        only_link: onlyLink,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 卖家发起退款损耗查询
   * @param {string} game 游戏
   */
  postSellerRefundPreview(_, {
    id, deductRatio,
  } = {}) {
    return Axios({
      method: 'post',
      url: api.postSellerRefundPreview(),
      data: {
        bill_order_id: id,
        seller_deduct_ratio: deductRatio,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 卖家发起退款
   * @param {string} game 游戏
   */
  getC2CSellerRefund(_, {
    id, refundReason, country, returnRefund, other = {},
  } = {}) {
    const data = {};
    if (country) {
      data.country = country;
    }
    return Axios({
      method: 'post',
      url: api.getC2CSellerRefund(),
      data: {
        bill_order_id: id,
        refund_reason: refundReason,
        return_refund: returnRefund,
        ...data,
        ...other,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 卖家获取通知模板列表
   */
  getBuyOrderSubmitNoticeList(_, {
    id,
  } = {}) {
    return Axios({
      method: 'get',
      url: api.getBuyOrderSubmitNoticeList(),
      params: {
        bill_order_id: id,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 卖家获取已登录fc25账号
   */
  getCutterLoginedAccount() {
    return Axios({
      method: 'get',
      url: api.getCutterLoginedAccount(),
    }).then((resp) => resp.data);
  },
  /**
   * 卖家获取fc25账号
   */
  getCutterAccount(_, data) {
    return Axios({
      method: 'get',
      params: data,
      url: api.getCutterAccount(),
    }).then((resp) => resp.data);
  },
  /**
   * 卖家登录fc25账号
   * @param {string} game 游戏
   */
  postCutterLogin(_, {
    account, pwd, backupcodes,
  } = {}) {
    return Axios({
      method: 'post',
      url: api.postCutterLogin(),
      data: {
        account,
        pwd,
        backupcodes,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 卖家退出登录fc25账号
   * @param {string} game 游戏
   */
  postCutterLoginOut(_, {
    account, pwd,
  } = {}) {
    return Axios({
      method: 'post',
      url: api.postCutterLoginOut(),
      data: {
        account,
        pwd,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 卖家登录fc25账号状态查询
   * @param {string} game 游戏
   */
  getCutterLoginResult(_, {
    reqId,
  } = {}) {
    return Axios({
      method: 'get',
      url: api.getCutterLoginResult(),
      params: {
        req_id: reqId,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 卖家登录fc25账号状态查询
   * @param {string} game 游戏
   */
  postCanSellCard(_, {
    account,
    pwd,
  } = {}) {
    return Axios({
      method: 'post',
      url: api.postCanSellCard(),
      data: {
        account,
        pwd,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 卖家修改fc25账号
   * @param {string} game 游戏
   */
  postUpdateAccount(_, {
    accountId,
    note,
    coins,
  } = {}) {
    return Axios({
      method: 'post',
      url: api.postUpdateAccount(),
      data: {
        account_id: accountId,
        note,
        left_coins: coins,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 卖家删除fc25账号
   * @param {string} game 游戏
   */
  postRemoveAccount(_, {
    accountId,
  } = {}) {
    return Axios({
      method: 'post',
      url: api.postRemoveAccount(),
      data: {
        account_id: accountId,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 卖家刷新fc25账号
   * @param {string} game 游戏
   */
  postRefreshAccount(_, {
    accountId,
  } = {}) {
    return Axios({
      method: 'post',
      url: api.postRefreshAccount(),
      data: {
        account_id: accountId,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 创建截卡记录
   * @param {string} game 游戏
   */
  postWhiteCardAdd(_, {
    playerId,
  } = {}) {
    return Axios({
      method: 'post',
      url: api.postWhiteCardAdd(),
      data: {
        player_id: playerId,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 创建截卡记录
   * @param {string} game 游戏
   */
  postBidOrderReq(_, {
    id, type, data,
  } = {}) {
    return Axios({
      method: 'post',
      url: api.postBidOrderReq(),
      data: {
        bill_order_id: id,
        type,
        ...data,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 截卡记录查询
   * @param {string} game 游戏
   */
  getBidOrderList(_, {
    pageSize, pageNum, data = {},
  } = {}) {
    return Axios({
      method: 'get',
      url: api.getBidOrderList(),
      params: {
        page_size: pageSize,
        page_num: pageNum,
        ...data,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 截卡记录状态查询
   * @param {string} game 游戏
   */
  getBidOrderState(_, {
    id,
  } = {}) {
    return Axios({
      method: 'get',
      url: api.getBidOrderState(),
      params: {
        id,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 截卡记录详情
   * @param {string} game 游戏
   */
  getBidOrderDetail(_, {
    id, returnLog = false,
  } = {}) {
    return Axios({
      method: 'get',
      url: api.getBidOrderDetail(),
      params: {
        id,
        return_log: returnLog,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 修改截卡记录状态
   * @param {string} game 游戏
   */
  postBidOrderChangeState(_, {
    id, toState,
  } = {}) {
    return Axios({
      method: 'post',
      url: api.postBidOrderChangeState(),
      data: {
        id,
        to_state: toState,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 截卡记录改为手动
   * @param {string} game 游戏
   */
  postBidOrderManual(_, {
    id,
  } = {}) {
    return Axios({
      method: 'post',
      url: api.postBidOrderManual(),
      data: {
        id,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 本日交易费率
   * @param {string} game 游戏
   */
  getBidOrderDayStatics(_, { data = {} }) {
    return Axios({
      method: 'get',
      url: api.getBidOrderDayStatics(),
      params: {
        ...data,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 卖家截卡
   * @param {string} game 游戏
   */
  postBuyCard(_, {
    account, pwd, id, playerId, rareflag,
    sellerPrice, buyerPrice, pollQuery, duration,
    name, rating, sellerStartPrice, buyerStartPrice, bidId,
  } = {}) {
    return Axios({
      method: 'post',
      url: api.postBuyCard(),
      data: {
        account,
        pwd,
        bill_order_id: id,
        bid_order_id: bidId,
        player_id: playerId,
        rareflag,
        seller_price: sellerPrice,
        buyer_price: buyerPrice,
        poll_query: pollQuery,
        duration,
        name,
        rating,
        seller_start_price: sellerStartPrice,
        buyer_start_price: buyerStartPrice,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 卖家截卡
   * @param {string} game 游戏
   */
  postReqAccountCapture(_, {
    id,
  } = {}) {
    return Axios({
      method: 'post',
      url: api.postReqAccountCapture(),
      data: {
        bill_order_id: id,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 截图结果查询
   * @param {string} game 游戏
   */
  getAccountCaptureResult(_, {
    id,
  } = {}) {
    return Axios({
      method: 'get',
      url: api.getAccountCaptureResult(),
      params: {
        bill_order_id: id,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 获取球员的搜索条件
   * @param {string} game 游戏
   */
  getCardSearchInfo() {
    return Axios({
      method: 'get',
      url: api.getCardSearchInfo(),
    }).then((resp) => resp.data);
  },
  /**
   * 搜索球员
   * @param {string} game 游戏
   */
  querySearchCard(_, data = {}) {
    return Axios({
      method: 'post',
      url: api.postSearchCard(),
      data: {
        ...data,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 卖家截卡状态查询
   * @param {string} game 游戏
   */
  getCutterBuyCardResult(_, {
    reqId,
  } = {}) {
    return Axios({
      method: 'get',
      url: api.getCutterBuyCardResult(),
      params: {
        req_id: reqId,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 卖家数据面板查询
   * @param {string} game 游戏
   */
  getSellerServiceData(_, {
    beginDate, endData, type, other = {},
  } = {}) {
    return Axios({
      method: 'get',
      url: api.getSellerServiceData(),
      params: {
        begin_date: beginDate,
        end_date: endData,
        group_type: type,
        ...other,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 查询球员价格
   * @param {string} game 游戏
   */
  getCardSellPrice(_, {
    accountId,
    playerId,
    rareflag,
  } = {}) {
    return Axios({
      method: 'get',
      url: api.getCardSellPrice(),
      params: {
        account_id: accountId,
        player_id: playerId,
        rareflag,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 卖家截卡状态查询
   * @param {string} game 游戏
   */
  getCalcSellPrice(_, {
    price, minPrice, maxPrice,
  } = {}) {
    return Axios({
      method: 'get',
      url: api.getCalcSellPrice(),
      params: {
        price,
        min_price: minPrice,
        max_price: maxPrice,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 返回被拉黑的球员卡信息
   * @param {string} game 游戏
   */
  getBlackCardInfo(_, {
    platform, bidState, sort,
  } = {}) {
    const data = {};
    if (bidState) {
      data.bid_state = bidState;
    }
    return Axios({
      method: 'get',
      url: api.getBlackCardInfo(),
      params: {
        platform,
        sort,
        ...data,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 更新截卡黑名单状态
   * @param {string} game 游戏
   */
  postBlackCardInfoUpdate(_, {
    playerId = [], platform, toState,
  } = {}) {
    return Axios({
      method: 'post',
      url: api.postBlackCardInfoUpdate(),
      data: {
        player_id: playerId,
        platform,
        to_state: toState,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 修改截卡记录信息
   * @param {string} game 游戏
   */
  postBidOrderUpdate(_, {
    id, addAmount,
  } = {}) {
    return Axios({
      method: 'post',
      url: api.postBidOrderUpdate(),
      data: {
        id,
        add_amount: addAmount,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 创建补发截卡
   * @param {string} game 游戏
   */
  postBidOrderCompensate(_, {
    id, type, buyerAccount, buyerPwd, backupCodes = [], data = {}, totalAmount, platform,
  } = {}) {
    return Axios({
      method: 'post',
      url: api.postBidOrderCompensate(),
      data: {
        bill_order_id: id,
        type,
        total_amount: totalAmount,
        platform,
        buyer_account: buyerAccount,
        buyer_pwd: buyerPwd,
        backup_codes: backupCodes,
        ...data,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 创建前检查
   * @param {string} game 游戏
   */
  postBidOrderPreview(_, {
    id, type, buyerAccount, compensate = false,
  } = {}) {
    return Axios({
      method: 'post',
      url: api.postBidOrderPreview(),
      data: {
        bill_order_id: id,
        type,
        buyer_account: buyerAccount,
        compensate,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 订单进度查询
   * @param {string} game 游戏
   */
  getCutterOrderProgress(_, {
    id,
  } = {}) {
    return Axios({
      method: 'post',
      url: api.getCutterOrderProgress(),
      data: {
        bill_order_id: id,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 卖家添加订单备注
   * @param {string} game 游戏
   */
  postC2CAddRemark(_, {
    id, game, remark,
  } = {}) {
    return Axios({
      method: 'post',
      url: api.postC2CAddRemark(),
      data: {
        bill_order_id: id,
        game,
        remark,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 卖家添加问题标记
   * @param {string} game 游戏
   */
  postC2CAddMark(_, {
    id, game, markList,
  } = {}) {
    return Axios({
      method: 'post',
      url: api.postC2CAddMark(),
      data: {
        bill_order_id: id,
        game,
        seller_mark_types: markList,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 卖家删除问题标记
   * @param {string} game 游戏
   */
  postC2CRemoveMark(_, {
    id, game, markList,
  } = {}) {
    return Axios({
      method: 'post',
      url: api.postC2CRemoveMark(),
      data: {
        bill_order_id: id,
        game,
        seller_mark_types: markList,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 获取六码博客列表
   * @param {string} game 游戏名
   */
  getSixHorseApi(_, { path, query } = {}) {
    const params = {
      path,
      query,
    };
    return Axios({
      method: 'get',
      url: api.getSixHorseApi(),
      params,
    }).then((resp) => resp.data);
  },
  /**
   * 获取游戏聚合页详情
   * @param {string} game 游戏名
   */
  getC2CAppGamePage(_, { game } = {}) {
    const params = {
      game,
    };
    return Axios({
      method: 'get',
      url: api.getC2CAppGamePage(),
      params,
    }).then((resp) => resp.data);
  },
  /**
   * 获取内购页的游戏详情
   * @param {string} game 游戏名
   */
  getC2cAppDetail({ getters: _getters }, { game, service = '', extra = {} } = {}) {
    const params = {
      game,
      ...extra,
      _l: _getters.textType,
    };
    if (service) {
      params.service = service;
    }
    return Axios({
      method: 'get',
      url: api.getC2CAppDetail(),
      params,
      useCache: {
        time: 30 * 60 * 1000, // 缓存时间设置: 30分钟
      },
    }).then((resp) => resp.data);
  },
  /**
   * 获取游戏销售
   * @param {string} game_list 游戏列表，多个用,分割
   */
  getC2cAppData(_, { gameList = [], service = '' } = {}) {
    const params = {
      game_list: gameList.join(','),
      service,
    };
    return Axios({
      method: 'get',
      url: api.getC2cAppData(),
      params,
    }).then((resp) => resp.data);
  },
  /**
   * 获取游戏业务类型的TKD
   * @param {string} game 游戏名
   * @param {string} service 业务类型
   */
  getMarketGameAppServiceTkd({ getters: _getters }, { game, service = '', goodsType } = {}) {
    const params = {
      game,
      _l: _getters.textType,
    };
    if (service) {
      params.service_type = service;
    }
    if (goodsType) {
      params.goods_type = goodsType;
    }
    return Axios({
      method: 'get',
      url: api.getMarketGameAppServiceTkd(),
      params,
    }).then((resp) => resp.data);
  },
  /**
   * 获取游戏支持的服务类型
   * @param {string} game 游戏名
   */
  getMarketGameAppServiceCategory({ getters: _getters }, { game } = {}) {
    const params = {
      game,
      _l: _getters.textType,
    };
    return Axios({
      method: 'get',
      url: api.getMarketGameAppServiceCategory(),
      params,
      useCache: {
        time: 5 * 60 * 1000,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 获取内购页的游戏详情v2
   * @param {string} game 游戏名
   */
  geMarketGameAppServiceDetail({ getters: _getters }, { game, service = '' } = {}) {
    const params = {
      game,
      _l: _getters.textType,
      ...(SessionStorage.get('UTM_PARAMS') || {}),
    };
    if (service) {
      params.service_type = service;
    }
    return Axios({
      method: 'get',
      url: api.geMarketGameAppServiceDetail(),
      params,
    }).then((resp) => resp.data);
  },
  geMarketGameAppServiceRoles(_, {
    game_app_category_id: gameAPPCategoryId, game, login_data: loginData,
  }) {
    const params = {
      game_app_category_id: gameAPPCategoryId,
      game,
      login_data: loginData,
    };
    return Axios({
      method: 'post',
      url: api.geMarketGameAppServiceRoles(),
      data: params,
    }).then((resp) => resp.data);
  },
  /**
   * 获取游戏sku列表v2
   * @param {string} game 游戏名
   */
  geMarketSku({ getters: _getters }, {
    pageNum, pageSize, sortBy,
    game, service, goodsType,
    spuId, attribute = {}, search,
    signal, roleid, hostid, fcLatestPromo,
  } = {}) {
    const params = {
      page_num: pageNum,
      page_size: pageSize,
      sort_by: sortBy,
      spu_id: spuId,
      game,
      service_type: service,
      goods_type: goodsType,
      attribute,
      role_id: roleid,
      host_id: hostid,
      search_keyword: search,
      fc_latest_promo: fcLatestPromo,
      ...(SessionStorage.get('UTM_PARAMS') || {}),
      _l: _getters.textType,
    };
    const config = {
      method: 'get',
      url: api.geMarketSku(),
      params,
    };
    if (signal) config.signal = signal;
    return Axios(config).then((resp) => resp.data);
  },
  /**
   * 获取游戏sku详情v2
   * @param {string} game 游戏名
   */
  async geMarketSkuDetail({ getters: _getters }, {
    game, sellOrderId,
  } = {}) {
    const params = {
      game,
      sell_order_id: sellOrderId,
      ...(SessionStorage.get('UTM_PARAMS') || {}),
      _l: _getters.textType,
    };
    let resp = null;
    if (client.version) {
      try {
        resp = await native('request_server_api', {
          path: '/v2/market/sku/detail',
          method: 'GET',
          params,
        });
      } catch (err) { /** */ }
    }
    if (!resp) {
      resp = await Axios({
        method: 'get',
        url: api.geMarketSkuDetail(),
        params,
      });
    }
    return resp.data;
  },
  postMarketBuyCartDetail(_, { game, sellOrders }) {
    return Axios({
      method: 'post',
      url: api.postMarketBuyCartDetail(),
      data: { game, sell_orders: sellOrders },
    }).then((resp) => resp.data);
  },
  getC2CBuyOrderList(_, {
    game, pageNum, pageSize, params = {}, signal,
  } = {}) {
    const data = {};
    Object.keys(params).forEach((key) => {
      if (params[key]) {
        data[key] = params[key];
      }
    });
    return Axios({
      method: 'get',
      url: api.getC2CBuyOrderList(),
      params: {
        game,
        page_num: pageNum,
        page_size: pageSize,
        ...data,
      },
      signal,
    }).then((resp) => resp.data);
  },
  /**
   * 通知买家补充信息
   * @param {string} game 游戏
   */
  postC2CUserSetMark(_, {
    buyerId, color, remark,
  } = {}) {
    return Axios({
      method: 'post',
      url: api.postC2CUserSetMark(),
      data: {
        buyer_id: buyerId,
        remark,
        color,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 通知买家补充信息
   * @param {string} game 游戏
   */
  getC2CBuyerSendSubmitNotice({ rootState }, {
    id, game, template,
  } = {}) {
    return Axios({
      method: 'post',
      url: api.getC2CBuyerSendSubmitNotice(),
      data: {
        game: rootState.game || game,
        bill_order_id: id,
        template,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 获取出售详情
   * @param {string} game 游戏
   */
  getC2CBuyOrderDetail({ rootState }, { id, game } = {}) {
    return Axios({
      method: 'get',
      url: api.getC2CBuyOrderDetail(),
      params: {
        game: rootState.game || game,
        bill_order_id: id,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 获取卖家ip
   * @param {string} game 游戏
   */
  getC2CSellerAddress({ rootState }, { id, game } = {}) {
    return Axios({
      method: 'get',
      url: api.getC2CSellerAddress(),
      params: {
        game: rootState.game || game,
        bill_order_id: id,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 获取卖家数据报表
   * @param {string} game 游戏
   */
  getC2CSellerDownload() {
    return Axios({
      method: 'get',
      url: api.getC2CSellerDownload(),
      responseType: 'blob',
    }).then((resp) => resp.data);
  },
  /**
   * 获取资金流水下载数据报表请求
   * @param {string} game 游戏
   */
  getC2CReqDownload({ rootState }, { startDate, endDate, game } = {}) {
    return Axios({
      method: 'get',
      url: api.getC2CReqDownload(),
      params: {
        game: rootState.game || game,
        begin_time: startDate,
        end_time: endDate,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 获取卖家中心下载数据报表请求
   * @param {string} game 游戏
   */
  getC2CSellerReqDownload({ rootState }, {
    game, buyerId, all, data = {},
  } = {}) {
    return Axios({
      method: 'post',
      url: api.getC2CSellerReqDownload(),
      data: {
        game: rootState.game || game,
        buyer_id: buyerId,
        all,
        ...data,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 获取资金流水下载数据报表请求
   * @param {string} game 游戏
   */
  getC2CReportReqDownloadd(_, { pageNum, pageSize, type } = {}) {
    return Axios({
      method: 'get',
      url: api.getC2CReportReqDownload(),
      params: {
        type,
        page_num: pageNum,
        page_size: pageSize,
      },
      metadata: { ignore_log: true },
    }).then((resp) => resp.data);
  },
  /**
   * 获取出售详情
   * @param {string} game 游戏
   */
  getC2CBuyOrderUpdate({ rootState }, {
    id, game, shopState, images, deliveryInfo, deliveryCountry,
  } = {}) {
    const data = {};
    if (deliveryCountry) {
      data.delivery_country = deliveryCountry;
    }
    return Axios({
      method: 'post',
      url: api.getC2CBuyOrderUpdate(),
      data: {
        game: rootState.game || game,
        bill_order_id: id,
        shop_state: shopState,
        images,
        delivery_info: deliveryInfo,
        ...data,
      },
    }).then((resp) => resp.data);
  },
  getBuyOrderDeliver({ rootState }, {
    id, game,
  } = {}) {
    return Axios({
      method: 'post',
      url: api.getBuyOrderDeliver(),
      data: {
        game: rootState.game || game,
        bill_order_id: id,
      },
    }).then((resp) => resp.data);
  },
  getBuyOrderSkipSubmit({ rootState }, {
    id, game,
  } = {}) {
    return Axios({
      method: 'post',
      url: api.getBuyOrderSkipSubmit(),
      data: {
        game: rootState.game || game,
        bill_order_id: id,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 添加评论
   * @param {string} game 游戏
  */
  getLootbarOrderCommentAdd({ rootState }, {
    id, game, stars, message, data = {},
  } = {}) {
    return Axios({
      method: 'post',
      url: api.getLootbarOrderCommentAdd(),
      data: {
        game: rootState.game || game,
        bill_order_id: id,
        stars,
        message,
        ...data,
      },
    }).then((resp) => resp.data);
  },
  /**
   * 获取评论详情
   */
  getLootbarOrderCommentDetail({ rootState }, { id, game } = {}) {
    return Axios({
      method: 'get',
      params: {
        game: rootState.game || game,
        bill_order_id: id,
      },
      url: api.getLootbarOrderCommentDetail(),
    }).then((resp) => resp.data);
  },
  getOrderCommentTrans(_, { commentId, game } = {}) {
    return Axios({
      method: 'get',
      params: {
        game,
        comment_id: commentId,
      },
      url: api.getOrderCommentTrans(),
    }).then((resp) => resp.data);
  },
  /**
   * 获取最新的评论图片审核不通过订单
   */
  getCommentLastModifyImg() {
    return Axios({
      method: 'get',
      url: api.getCommentLastModifyImg(),
    }).then((resp) => resp.data);
  },
  /**
   * 修改评论
   */
  postModifyComment({ rootState }, {
    id, game, stars, message, data = {},
  } = {}) {
    return Axios({
      method: 'post',
      data: {
        game: rootState.game || game,
        bill_order_id: id,
        stars,
        message,
        ...data,
      },
      url: api.postModifyComment(),
    }).then((resp) => resp.data);
  },
  /**
   * 点赞评论
   */
  postAccountCommentUp(_, {
    commentId, action,
  } = {}) {
    return Axios({
      method: 'post',
      data: {
        comment_id: commentId,
        action,
      },
      url: api.postAccountCommentUp(),
    }).then((resp) => resp.data);
  },
  /**
   * 添加订单评价检查
   */
  getOrderCommentAddCheck(_, {
    id,
  } = {}) {
    return Axios({
      method: 'get',
      params: {
        bill_order_id: id,
      },
      url: api.getOrderCommentAddCheck(),
    }).then((resp) => resp.data);
  },
  /**
   * 查询评论列表
   * bill_order_id: id,
   * service：String，服务类型，可选，取值为dict_keys(['recharge', 'trade', 'cdkey', 'new_account'])其中之一
   * sell_order_id：InstanceIDField，出售订单ID，可选
   * other_lang：Boolean，是否其他语言，可选
   */
  getSellOrderCommentQuery({ rootState, getters: _getters }, {
    game, pageNum, pageSize, params = {}, sortBy,
  } = {}) {
    const data = {};
    Object.keys(params).forEach((key) => {
      if (params[key]) {
        data[key] = params[key];
      }
    });
    return Axios({
      method: 'get',
      params: {
        game: rootState.game || game,
        page_num: pageNum,
        page_size: pageSize,
        sort_by: sortBy,
        ...data,
        _l: _getters.textType,
      },
      url: api.getSellOrderCommentQuery(),
    }).then((resp) => resp.data);
  },
  /**
   * 获取首页模块数据
   */
  getHomeModule({ getters: _getters }, data = {}) {
    const config = {
      method: 'get',
      url: api.getHomeModule(),
      params: {
        ...(SessionStorage.get('UTM_PARAMS') || {}),
        _l: _getters.textType,
      },
    };
    if (data.signal) config.signal = data.signal;
    return Axios(config).then((resp) => resp.data);
  },
  bindC2CKnivesoutRole(_, params) {
    return Axios({
      method: 'post',
      url: api.bindC2CKnivesoutRole(),
      data: params,
    }).then((resp) => resp.data);
  },
  unbindC2CKnivesoutRole(_, params) {
    return Axios({
      method: 'post',
      url: api.unbindC2CKnivesoutRole(),
      data: params,
    }).then((resp) => resp.data);
  },
  postBuyVerifySubmit(_, params) {
    return Axios({
      method: 'post',
      url: api.postBuyVerifySubmit(),
      data: params,
    }).then((resp) => resp.data);
  },
  getBuyQueryVerifySubmitResult(_, {
    id, taskId, isErr = true,
  } = {}) {
    const data = {};
    if (!isErr) {
      data.filter_not_support_verify_err = isErr;
    }
    return Axios({
      method: 'get',
      params: {
        bill_order_id: id,
        task_id: taskId,
        ...data,
      },
      url: api.getBuyQueryVerifySubmitResult(),
    }).then((resp) => resp.data);
  },
  extraQueryProfile(_, params) {
    return Axios({
      method: 'post',
      url: api.extraQueryProfile(),
      data: params,
    }).then((resp) => resp.data);
  },
  getGenshinImpactProfile(_, params) {
    const { sync, uid } = params;
    params = { uid };
    if (sync) params.sync = 1;
    return Axios({
      method: 'get',
      url: api.getGenshinImpactProfile(),
      params,
    }).then((resp) => resp.data);
  },
  getGenshinImpactProfileHistory() {
    return Axios({
      method: 'get',
      url: api.getGenshinImpactProfileHistory(),
    }).then((resp) => resp.data);
  },
  reqBuyCard(_, params) {
    return Axios({
      method: 'post',
      url: api.reqBuyCard(),
      data: params,
    }).then((resp) => resp.data);
  },
  queryBuyCardResult(_, params) {
    return Axios({
      method: 'get',
      url: api.queryBuyCardResult(),
      params,
    }).then((resp) => resp.data);
  },
  getPointTaskList(_, params = {}) {
    return Axios({
      method: 'get',
      url: api.getPointTaskList(),
      params,
    }).then((resp) => resp.data);
  },
  getPointTaskDetail(_, { taskClassId, scene } = {}) {
    return Axios({
      method: 'get',
      url: api.getPointTaskDetail(),
      params: {
        task_class_id: taskClassId,
        scene,
      },
      metadata: { ignore_log: true },
    }).then((resp) => resp.data);
  },
  postMarketBuyOrderPsNotified(_, params) {
    return Axios({
      method: 'post',
      url: api.postMarketBuyOrderPsNotified(),
      data: params,
    }).then((resp) => resp.data);
  },
};

const module = {
  state, getters, mutations, actions,
};

export default module;
