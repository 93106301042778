export default function getWindow(node) {
  if (node.toString() !== '[object Window]') {
    const { ownerDocument } = node;
    return ownerDocument ? ownerDocument.defaultView || window : window;
  }
  return node;
}

export function isElement(node) {
  const OwnElement = getWindow(node).Element;
  return node instanceof OwnElement || node instanceof Element;
}

export function isHTMLElement(node) {
  const OwnElement = getWindow(node).HTMLElement;
  return node instanceof OwnElement || node instanceof HTMLElement;
}

export function getComputedStyle(element) {
  return window.getComputedStyle(element);
}

export function isScrollParent(element) {
  // Firefox wants us to check `-x` and `-y` variations as well
  const { overflow, overflowX, overflowY } = getComputedStyle(element);
  return /auto|scroll|overlay|hidden/.test(overflow + overflowY + overflowX);
}

export function getNodeName(element) {
  return element ? (element.nodeName || '').toLowerCase() : null;
}
export function getParentNode(element) {
  if (getNodeName(element) === 'html') {
    return element;
  }
  return element.parentNode;
}

export function getScrollParent(node) {
  if (['html', 'body', '#document'].indexOf(getNodeName(node)) >= 0) {
    return node.ownerDocument.body;
  }
  if (isHTMLElement(node) && isScrollParent(node)) {
    return node;
  }
  return getScrollParent(getParentNode(node));
}

export function scrollState(el, boundary = {}) {
  const {
    left: boundaryLeft = 0,
    right: boundaryRight = 0,
    top: boundaryTop = 0,
    bottom: boundaryBottom = 0,
  } = boundary;
  const {
    scrollTop, offsetHeight, scrollHeight,
    scrollLeft, offsetWidth, scrollWidth,
  } = el;
  const shimHeight = Math.ceil(offsetHeight + scrollTop);
  const shimWidth = Math.ceil(offsetWidth + scrollLeft);
  const state = { x: { start: false, end: false }, y: { start: false, end: false } };
  if (scrollTop <= boundaryTop) state.y.start = true;
  if (scrollHeight - shimHeight <= boundaryBottom) state.y.end = true;
  if (scrollLeft <= boundaryLeft) state.x.start = true;
  if (scrollWidth - shimWidth <= boundaryRight) state.x.end = true;
  return state;
}

export function offset(el, wrap) {
  const obj = {
    offsetLeft: 0,
    offsetTop: 0,
  };
  let temp = el;
  while (temp && temp.parentNode !== wrap) {
    const { offsetLeft, offsetTop } = temp;
    obj.offsetLeft += offsetLeft;
    obj.offsetTop += offsetTop;
    temp = temp.parentNode;
  }
  if (!temp) return null;
  const { offsetLeft, offsetTop } = temp;
  obj.offsetLeft += offsetLeft;
  obj.offsetTop += offsetTop;
  return obj;
}

// 获取类名获取父节点或者曾父节点
export function getParentByClassName(el, cls) {
  let par = el;
  let bool = true;
  let className = '';
  while (bool) {
    par = par.parentNode;
    // documnet
    if (!par.tagName) {
      bool = false;
      par = el;
    }
    className = par.className;
    if (className && className.indexOf(cls) !== -1) {
      bool = false;
    }
  }
  return par;
}
