<template>
  <w-dialog-new
    :show="show"
    :class="theme"
    :title="title"
    :title-icon="titleIcon"
    :content="content"
    :size="size"
    :max-height="maxHeight"
    :close-btn="closeBtn"
    :cancel-btn="cancelBtn"
    :confirm-btn="confirmBtn"
    :loading="loading"
    :actions-direction="actionsDirection"
    :confirm-text="confirmText"
    :cancel-text="cancelText"
    :buttons="buttons"
    @confirm="handleConfirm"
    @cancel="handleCancel"
    @close="handleClose" />
</template>

<script>
import { DialogState } from '@/enums';

export default {
  props: {
    show: Boolean,
    title: String,
    titleIcon: String,
    content: String,
    maxHeight: Number,
    size: String,
    actionsDirection: String,
    closeBtn: [Boolean, Object],
    cancelBtn: [Boolean, Object],
    confirmBtn: [Boolean, Object],
    confirmText: {
      type: String,
      default: '',
    },
    cancelText: {
      type: String,
      default: '',
    },
    theme: String,
    buttons: {
      type: Array,
      default() { return []; },
    },
  },
  data() {
    return {
      loading: false,

      onConfirm: null,
      onCancel: null,
    };
  },
  methods: {
    handleClose(state = DialogState.close) {
      this.$emit('close', state);
    },
    async handleConfirm() {
      let remainDialog = false;
      if (this.onConfirm) {
        this.loading = true;
        try {
          remainDialog = await this.onConfirm();
        } catch (err) {
          remainDialog = true;
        }
        this.loading = false;
      }
      if (!remainDialog) this.handleClose(DialogState.confirm);
    },
    async handleCancel() {
      let remainDialog = false;
      if (this.onCancel) {
        this.loading = true;
        try {
          remainDialog = await this.onCancel();
        } catch (err) {
          remainDialog = true;
        }
        this.loading = false;
      }
      if (!remainDialog) this.handleClose(DialogState.cancel);
    },
  },
};
</script>

<style lang="less" scoped>
.p-dialog-content {
  :deep(a) {
    text-decoration: underline;
    color: @tint_primary_2;
    &:hover {
      color: @tint_primary_1;
    }
    &:active {
      color: @tint_primary_2;
    }
  }
}
</style>
