<template>
  <div :class="mobileWrapFixedCss">
    <!-- pc端： fixed在nav下面, 因要改变App整体高度，样式放在App.desktop.c2c.vue中由主程序控制-->
    <div v-if="showFixGuide && inPc" class="regCoupon-tips">
      <i class="icon icon_coupon"></i>
      <p class="font16r">{{ $t('$share.regTitle') }}</p>
      <p
        v-if="title"
        class="regCoupon-tips-warn font16b"
        @click="handRegCouponConfirm('bar')"
        v-html="title"></p>
    </div>
    <!-- 移动端：fixed在页面底部，由当前组件自行显示位置处理 -->
    <div v-else-if="showFixGuide && !inPc"
      class="regCoupon-tips inMobile" :class="{isBottom: bottomStyle.bottom==='0px'}"
      :style="bottomStyle">
      <i class="icon icon_coupon"></i>
      <p
        v-if="title"
        class="regCoupon-tips-warn"
        v-html="title"></p>
      <w-button
        text type="secondary"
        @click="handRegCouponConfirm('bar')">
        {{ $t('global.sign_up') }}
        <w-icon name="icon_arrow_right" :size="12" />
      </w-button>
      <w-button class="regCoupon-tips-close" icon name="icon_close" @click="onCloseGuide" />
    </div>
    <DialogCoupon
      :show="show"
      :type="isSave ? 'type2' : 'type1'"
      :desc="isSave ? $t('new_coupon.save_reg_desc_one') : title"
      :coupons="regCoupon?.coupons"
      :coupon-bg="regCoupon?.coupon_background_img"
      :confirm-btn-text="isSave ? $t('new_coupon.coutinue') : regCoupon?.btn_text"
      :cancel-btn-text="isSave ? $t('new_coupon.give_up') : ''"
      :need-cancel-btn="!!isSave"
      :bonus-text="regCoupon?.bonus_text"
      :remain-time="remainTime"
      :hand-show="isSave ? handInviteShow : handShow"
      :extra="regCoupon?.display_setting || {}"
      :share-user-info="regCoupon.share_user_info"
      @confirm="isSave ? handRegInviteCouponBtn() : handRegCouponBtn()"
      @cancel="isSave ? giveUpCoupon() : ''"
      @close="onClose(true)" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { ProfileGuideEnum } from '@/enums';
import SessionStorage from '@/utils/session-storage';
import LocalStorage from '@/utils/local-storage';
import bus from '@/utils/bus';
import { isTiktok } from '@/utils';
import DialogCoupon from '@/views/components/DialogCoupon/DialogCoupon.vue';

// 不弹窗 直接折叠新客优惠的路由
const BlockRouteList = ['AffiliateProgram.Guide', 'AffiliateProgram.Award', 'Download', 'Sell', 'Sell.Fc25-Coins'];

// 移动端底部位置
const BOTTOM = {
  Topup: {
    ready: `${120 + 0}px`,
    other: `${0}px`,
  },
  List: {
    ready: `${0}px`,
    other: `${0}px`,
  },
  Detail: {
    ready: `${56 + 0}px`,
    other: `${56 + 0}px`,
  },
  Coin: {
    ready: `${88 + 0}px`,
    other: `${0}px`,
  },
  Payment: {
    ready: `${64 + 0}px`,
    other: `${64 + 0}px`,
  },
};

export default {
  components: {
    DialogCoupon,
  },
  props: {
    inPc: Boolean,
    showModal: Boolean,
  },
  data() {
    return {
      showFixGuide: false, // 显示指引
      show: false,
      showStorageKey: 'REG_COUPON_DIALOG',
      regCoupon: {}, // 受邀请注册优惠券信息
      inRegGuide: false, // 是否处于注册引导中
      partailIsReadey: false, // 局部是否已准备好
      isSave: false, // 是否显示放弃弹窗
      remainTime: 0,
      handShow: true, // 是否显示小手
      handInviteShow: true, // 是否显示邀请小手
      countdownInterval: null,
    };
  },
  computed: {
    ...mapGetters(['delayShareToken', 'userInfo', 'textType', 'preventNewCustomerDialog']),
    isLogin() {
      return !!(this.userInfo && this.userInfo.uid);
    },
    title() {
      return this.regCoupon.popup_title || '';
    },
    mobileWrapFixedCss() {
      // 移动端端并且显示指引时才显示
      if (!this.inPc && this.showFixGuide) {
        return 'regCoupon-tips-mobile';
      }
      return '';
    },
    bottomStyle() {
      const { name } = this.$route;
      const bottom = BOTTOM[name] || {};
      if (typeof this.partailIsReadey === 'number') {
        return {
          bottom: `${this.partailIsReadey}px`,
        };
      }
      return {
        bottom: bottom[this.partailIsReadey ? 'ready' : 'other'] || '0px',
      };
    },
  },
  watch: {
    // 目前注册的不需要获取优惠券信息了，优先注册其它相关的提示， 后续需要开启在这里处理吧
    // delayShareToken() {
    //   this.getUserShareDialog();
    // },
    showModal(n, o) {
      if (n && !o) {
        this.getRegCouponDialog();
        return;
      }
      // 其它情况，关闭注册引导导航栏
      this.setFixedGuideDisplay({
        show: false,
      });
      LocalStorage.remove(this.showStorageKey);
    },
    showFixGuide(n) {
      this.$emit('setAppStatus', {
        status: 'inRegCoupon',
        value: n,
      });
      // 显示fix指引时，埋点
      if (n) {
        this.$logger('c2c_new_user_coupon_bar', {
          type: this.regCoupon?.activity_type || '',
        });
      }
    },
    $route() {
      if (!this.inPc) {
        // 切路由就强制关闭局部的
        this.partailIsReadey = false;
      }
      if (this.show) {
        this.onClose();
      } else {
        this.setFixedGuideDisplay({
          show: true,
        });
      }
    },
    textType(n, o) {
      if (o && n !== o) {
        this.refreshCoupon();
      }
    },
    isLogin(val) {
      if (val && this.show) {
        this.onHide();
      }
    },
    preventNewCustomerDialog(val) {
      if (val) {
        this.onClose();
      }
    },
  },
  created() {
    this.getRegCouponDialog();
  },
  mounted() {
    this.bind();
  },
  destroyed() {
    this.unbind();
  },
  beforeDestroy() {
    clearInterval(this.countdownInterval);
  },
  methods: {
    handRegCouponBtn() {
      const currentTimestamp = Date.now();
      if (!LocalStorage.get('handShow')) {
        LocalStorage.set('handShow', currentTimestamp);
      }
      this.handRegCouponConfirm('dialog');
    },
    handRegInviteCouponBtn() {
      const currentTimestamp = Date.now();
      if (!LocalStorage.get('handInviteShow')) {
        LocalStorage.set('handInviteShow', currentTimestamp);
      }
      this.handRegCouponConfirm('dialog');
    },
    countdownTime() {
      const currentTimestamp = Math.floor(Date.now() / 1000);
      // 获取今天 23:59:59 的时间戳
      let todayEndTimestamp = new Date();
      todayEndTimestamp.setHours(23, 59, 59, 0);
      todayEndTimestamp = Math.floor(todayEndTimestamp.getTime() / 1000);
      // 计算剩余时间
      this.remainTime = todayEndTimestamp - currentTimestamp;
    },
    giveUpCoupon() {
      this.onClose();
    },
    // 切换语言刷新重新获取语言进行替换
    refreshCoupon() {
      // 登录中不可处理
      if (this.isLogin) {
        return;
      }
      // 有邮箱注册相关信息等延迟获取优惠券信息时， 则不获取优惠券信息
      if (this.delayShareToken) {
        return;
      }
      // 没有优惠信息时，不可显示
      if (!this.title) {
        return;
      }
      // 进行替换当前文案的内容
      const params = this.getRegCouponParams();
      this.$store.dispatch('getRegCoupon', params).then((res) => {
        const activity = res || {};
        this.setRegCouponText(activity);
        if (activity.popup_title) {
          this.regCoupon = activity;
          this.setLocalRegCoupon();
        }
      }).catch(() => {}).finally(() => {
        this.setFixedGuideDisplay({
          show: this.regCoupon?.popup_title || false,
        });
      });
    },
    getRegCouponParams() {
      const SHARETOKEN = SessionStorage.get('SHARE_TOKEN');
      const shareToken = this.$route.query?.share_token || SHARETOKEN || '';
      const params = {
        ...(SessionStorage.get('UTM_PARAMS') || {}),
      };
      if (shareToken) {
        params.share_token = shareToken;
      }
      return params;
    },
    // 未登录时，获取受邀请注册弹窗
    getRegCouponDialog() {
      // 登录中不可处理
      if (this.isLogin) {
        return;
      }
      // 不可显示不处理
      if (!this.showModal) {
        return;
      }
      // 有邮箱注册相关信息等延迟获取优惠券信息时， 则不获取优惠券信息
      if (this.delayShareToken) {
        return;
      }
      const hasShowDialog = LocalStorage.get(this.showStorageKey);
      if (hasShowDialog && typeof hasShowDialog === 'object' && hasShowDialog.textType === this.textType) {
        this.regCoupon = hasShowDialog;
        this.onHide();
        return;
      }
      const params = this.getRegCouponParams();
      this.$store.dispatch('getRegCoupon', params).then((res) => {
        const activity = res || {};
        this.setRegCouponText(activity);
        if (activity.popup_title) {
          this.regCoupon = activity;
          // this.regCoupon.coupons.push(...activity.coupons);
          this.show = true;
          if (!activity?.bonus_text) {
            this.countdownTime();
          }
          this.handShow = this.setHandShow();
          this.handInviteShow = this.setHandInviteShow();
          this.$logger('c2c_new_user_coupon_pop', {
            type: this.regCoupon?.activity_type || '',
          });
          if (this.preventNewCustomerDialog || BlockRouteList.includes(this.$route.name)) {
            this.onClose();
          }
        } else {
          this.onHide();
        }
      }).catch(() => {});
    },
    setRegCouponText(activity) {
      if (activity?.activity_type === 'reg') {
        activity.popup_title = this.$t('new_coupon.reg_title');
      } else if (activity?.activity_type === 'share') {
        activity.popup_title = this.$t('new_coupon.share_title');
      }
      if (activity?.coupons?.length > 1 && this.textType === 'en') {
        activity.popup_title += 's';
      }
      if (activity?.coupons?.length > 1) {
        activity.btn_text = this.$t('new_coupon.collet_all');
      } else {
        activity.btn_text = this.$t('new_coupon.collet_coupon');
      }
      let regCouponTip = '';
      const couponType = activity?.coupons[0]?.coupon_type;
      if (couponType === 1) {
        const _discount = activity?.coupons[0]?.coupon_setting?.discount || 0;
        const discount = new window.Big(_discount).times(100).toNumber();
        regCouponTip = activity?.coupons?.length > 1 ? this.$t('new_coupon.invite_reg_desc_three', { discount })
          : this.$t('new_coupon.invite_reg_desc_one', { discount });
      } else {
        const { off } = activity?.coupons[0]?.coupon_setting;
        const { formatPrice } = this.$options.filters;
        const discount = this.currencySymbol + formatPrice(new window.Big(off).div(100).toString());
        regCouponTip = activity?.coupons?.length > 1 ? this.$t('new_coupon.invite_reg_desc_four', { discount })
          : this.$t('new_coupon.invite_reg_desc_two', { discount });
      }
      this.$store.commit('SET_REG_COUPON_TIP', regCouponTip);
    },
    // 未登录时，点击注册并领取
    handRegCouponConfirm(position = 'dialog') {
      const logName = position === 'dialog' ? 'c2c_new_user_coupon_pop_register' : 'c2c_new_user_coupon_bar_register';
      this.$logger(logName, {
        type: this.regCoupon?.activity_type || '',
        ab: this.$abtest.getVariant('share_tip'),
      });
      this.show = false;
      if (isTiktok()) {
        this.$reg(ProfileGuideEnum.signinTiktok, {}).catch(() => {})
          .finally(() => { this.onClose(); });
        return;
      }
      this.$guide(ProfileGuideEnum.signin).then(() => {
        this.$store.commit('SET_REG_COUPON_TIP', '');
        this.onClose();
      }).catch((err) => {
        if (this.saveRegDialog() && err !== 'nosave') {
          this.show = true;
          this.isSave = true;
        } else {
          this.onClose();
        }
      });
    },
    saveRegDialog() {
      const currentTimestamp = Date.now();
      if (LocalStorage.get('saveReg')) {
        const lastTimestamp = LocalStorage.get('saveReg');
        const week = 1000 * 60 * 60 * 24 * 7;
        return currentTimestamp - lastTimestamp > week;
      }
      LocalStorage.set('saveReg', currentTimestamp);
      return true;
    },
    setHandShow() {
      const currentTimestamp = Date.now();
      if (LocalStorage.get('handShow')) {
        const lastTimestamp = LocalStorage.get('handShow');
        const week = 1000 * 60 * 60 * 24 * 7;
        return currentTimestamp - lastTimestamp > week;
      }
      return true;
    },
    setHandInviteShow() {
      const currentTimestamp = Date.now();
      if (LocalStorage.get('handInviteShow')) {
        const lastTimestamp = LocalStorage.get('handInviteShow');
        const week = 1000 * 60 * 60 * 24 * 7;
        return currentTimestamp - lastTimestamp > week;
      }
      return true;
    },
    setLocalRegCoupon() {
      if (!this.regCoupon.popup_title) {
        return;
      }
      // 当天还剩多少s才获取邀请注册的信息
      const secondsLeft = Math.floor((new Date().setHours(23, 59, 59, 999) - new Date()));
      const content = { ...this.regCoupon, textType: this.textType };
      LocalStorage.set(this.showStorageKey, content, secondsLeft);
    },
    // 用户主动关闭优惠弹窗，关闭弹窗后，当天不再获取邀请注册的信息,同时fix到固定区域
    onClose(hasLog) {
      this.show = false;
      this.setFixedGuideDisplay({
        show: true,
      });
      // 确认埋点
      if (hasLog) {
        this.$logger('c2c_new_user_coupon_pop_close', {
          type: this.regCoupon?.activity_type || '',
        });
      }
      this.setLocalRegCoupon();
      this.$store.commit('SET_REG_COUPON_TIP', '');
      this.$emit('close');
    },
    onHide() {
      this.show = false;
      this.setFixedGuideDisplay({
        show: true,
      });
      this.setLocalRegCoupon();
      this.$emit('hide');
    },
    // 关闭指引提示
    onCloseGuide() {
      this.setFixedGuideDisplay({
        show: false,
      });
    },
    // 设置指引显示状态
    setFixedGuideDisplay({ show = false } = {}) {
      /* fix指示区域要关闭
       * 1.存在弹窗
       * 2.邮箱注册指引
       * 3.不存优惠券信息
       * 4.已登录中
       */
      if (this.show
      || this.delayShareToken
      || !this.title
      || this.isLogin) {
        this.showFixGuide = false;
        return;
      }
      // 当前来关闭指引，不用区分都进行关闭
      if (!show) {
        this.showFixGuide = false;
        return;
      }
      // pc端确认显示则显示
      if (this.inPc) {
        if (this.$route.name === 'GamePage') {
          this.showFixGuide = false;
          return;
        }
        this.showFixGuide = show;
        return;
      }
      const { name } = this.$route;
      // 移动端切换页面需要继续出现fixGuide指引
      if (BOTTOM[name]) {
        this.showFixGuide = true;
      } else {
        this.showFixGuide = false;
      }
    },
    setPartialState({ isReadey = false } = {}) {
      const { name } = this.$route;
      if (BOTTOM[name] && isReadey) {
        this.partailIsReadey = isReadey;
      } else {
        this.partailIsReadey = false;
      }
    },
    // 绑定局部状态到当前组件
    bind() {
      this.unbind();
      bus.$on('syncPartialToGlobalState', this.setPartialState);
    },
    unbind() {
      bus.$off('syncPartialToGlobalState', this.setPartialState);
    },
  },
};
</script>

<style lang="less" scoped>
.regCoupon-tips {
  height: 44px;
  width: 100%;
  top: 80px;
  text-align: center;
  background: var(--fu-yellow3);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.3s linear;
  &.inMobile {
    top: unset;
    top: inherit;
    position: fixed;
    height: 40px;
    padding: 0 16px;
    justify-content: flex-start;
    .appbar-bottom(margin-bottom);
    &.isBottom {
      .appbar-bottom(padding-bottom);
      .appbar-bottom(height, 40px);
      margin-bottom: 0;
    }
    .regCoupon-tips-warn {
      margin-left: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-decoration-line: none;
      .fontNormal();
      &:hover {
        opacity: 1;
      }
    }
    p {
      font-size: 15px;
    }
    .w-button {
      font-size: 15px;
      margin-left: 12px;
      flex-shrink: 0;
      margin-right: 16px;
      svg {
        margin-top: 4px;
      }
    }
    .regCoupon-tips-close {
      margin-left: auto;
      width: 16px;
      height: 16px;
      color: rgba(255, 149, 0, 1);
      margin-right: 0;
    }
  }
  .icon_coupon {
    width: 29px;
    height: 24px;
    background: url('~@/assets/images/icon_coupon.png') no-repeat;
    background-size: contain;
    margin-right: 6px;
    flex-shrink: 0;
  }
  p {
    font-size: 16px;
    .fontBold();
  }
  &-warn {
    cursor: pointer;
    margin-left: 12px;
    color: #eb4b4b;
    text-decoration-line: underline;
    &:hover {
      .hover();
    }
  }
}
.regCoupon-tips-mobile {
  .appbar-bottom(padding-bottom, 40px);
  box-sizing: content-box;
}
</style>
